import org.w3c.dom.Audio

actual class AudioPlayer {
    private var audio: Audio? = null
    actual fun playAudio(url: String, muted: Boolean, onProgressUpdate: (Double) -> Unit) {
        audio?.pause()
        audio = Audio(url)
        audio?.volume = 0.5
        audio?.autoplay = true
        audio?.muted = muted
        audio?.play()
        audio?.ontimeupdate = { update ->
            val currentTime = audio?.currentTime
            val duration = audio?.duration
            if (currentTime != null && duration != null) {
                onProgressUpdate(currentTime / duration)
            }
        }
    }

    actual fun mute(muted: Boolean) {
        audio?.muted = muted
    }

    actual fun stopAudio() {
        audio?.pause()
        audio = null
    }
}