
import dev.gitlive.firebase.auth.externals.GoogleAuthProvider
import dev.gitlive.firebase.auth.externals.getAuth
import dev.gitlive.firebase.auth.externals.signInWithPopup
import dev.gitlive.firebase.externals.getApp

actual class AuthProvider actual constructor() {
    actual fun signInWithGoogle() {
        signInWithPopup(getAuth(getApp()), GoogleAuthProvider())
    }
}