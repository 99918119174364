
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.ComposeViewport
import androidx.navigation.compose.rememberNavController
import app.softwork.routingcompose.Parameters
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.initialize
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.Location

@OptIn(ExperimentalComposeUiApi::class)
fun main() {

    Firebase.initialize(options = FirebaseOptions(
        apiKey = "AIzaSyAE6HtssoC5sdUP_IKZNtK_pRhC2QWfMh8",
        authDomain = "loca-b39d6.firebaseapp.com",
        projectId = "loca-b39d6",
        storageBucket = "loca-b39d6.appspot.com",
        gcmSenderId = "740600741053",
        applicationId = "1:740600741053:web:793b8a016a12d0b285cbd4",
        gaTrackingId = "G-04VSYFZ6VT"
    ))

    onWasmReady {

        renderComposable("ComposeTarget") {
            var innerCompose = remember {
                val div = document.createElement("div") as HTMLDivElement
                div.setAttribute("tabindex", "0")
                div.id = "ComposeViewport"

                document.getElementById("ComposeTarget")?.appendChild(div)

                ComposeViewport("ComposeViewport") {
                    CompositionLocalProvider(LocalLayerContainer provides document.body!!) {
                        WebApp()
                    }
                }
            }
        }
    }
}

@Composable
fun WebApp() {
    val currentLocation: MutableState<String> = mutableStateOf(window.location.newPath().takeUnless { it == "/" } ?: "/")
    val currentPath: Path = Path.from(currentLocation.value)
    val navController = rememberNavController()

    val locaRouter by remember { mutableStateOf<LocaRouter>(
        object : LocaRouter {
            override fun push(path: String) {
                window.history.pushState(null, "", path)
                /*
                 The history API unfortunately provides no callback to listen to
                 [window.history.pushState], so we need to notify subscribers when pushing a new path.
                 */
                currentLocation.value = window.location.newPath()
            }

            override fun pop() {
                window.history.back()
            }

            override fun changeTitle(title: String) {
                document.title = title
            }
        }
    ) }



    LaunchedEffect(Unit) {
        window.onpopstate = {
            currentLocation.value = window.location.newPath()
            navController.popBackStack()
            Unit
        }
    }

    App(
        deepLink = currentPath.path,
        router = locaRouter,
        navController = navController
    )
}

private fun Location.newPath() = "$pathname$search"

data class Path(val path: String, val parameters: Parameters?) {
    internal fun newPath(currentPath: String) = Path(path = path.removePrefix("/$currentPath"), parameters)

    /**
     * https://datatracker.ietf.org/doc/html/rfc1808
     */
    internal fun relative(to: String): Path {
        val paths = path.split("/")
        val split = to.split("./")
        val result = split.last().let {
            if (it.isNotEmpty()) "/$it" else it
        }
        val number = split.count() - 1
        return from(
            paths.dropLast(number).joinToString(postfix = result, separator = "/") {
                it
            }
        )
    }

    internal companion object {
        fun from(rawPath: String): Path {
            val pathAndQuery = rawPath.split("?")
            val (path, rawParameters) = when (pathAndQuery.size) {
                1 -> {
                    pathAndQuery.first() to null
                }

                2 -> {
                    pathAndQuery.first() to pathAndQuery.last().let { Parameters.from(it) }
                }

                else -> {
                    error("path contains more than 1 '?' delimiter: $rawPath")
                }
            }
            return Path(path.addPrefix("/"), rawParameters)
        }

        private fun String.addPrefix(prefix: String) = if (startsWith(prefix)) this else "$prefix$this"
    }

    override fun toString(): String = if (parameters == null) {
        path
    } else {
        "$path?$parameters"
    }

    internal val currentPath get() = path.removePrefix("/").takeWhile { it != '/' }
}
