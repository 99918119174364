package ui

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import data.LocaNavigator
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.FirebaseAnalytics
import dev.gitlive.firebase.analytics.FirebaseAnalyticsEvents
import dev.gitlive.firebase.analytics.FirebaseAnalyticsParam
import dev.gitlive.firebase.analytics.FirebaseAnalyticsParameters
import dev.gitlive.firebase.analytics.analytics
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.logo
import org.jetbrains.compose.resources.vectorResource

@Composable
fun LandingPage(
    onRegisterClick: () -> Unit,
    onLoginClick: () -> Unit,
) {

    val analytics = Firebase.analytics

    LaunchedEffect(true) {
        analytics.logEvent(FirebaseAnalyticsEvents.SCREEN_VIEW, parameters = mapOf(
            "screen_name" to "LandingPage"
        ))
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .padding(horizontal = 24.dp)
    ) {
        Column(
            modifier = Modifier
                .align(Alignment.Center)
        ) {


            Icon(
                modifier = Modifier
                    .size(148.dp),
                imageVector = vectorResource(Res.drawable.logo),
                contentDescription = "Loca",
            )

            Spacer(Modifier.height(48.dp))

            Text(
                text = "We're building a home for local music.",
                fontWeight = FontWeight.Bold,
                fontSize = 52.sp,
                lineHeight = 64.sp,
            )

            Spacer(Modifier.height(48.dp))

            Button(
                modifier = Modifier,
                onClick = {
                    onRegisterClick()
                },
                shape = CircleShape
            ) {
                Text(
                    modifier = Modifier.padding(12.dp),
                    text = "Make your artist profile",
                    fontSize = 16.sp
                )
            }

            Spacer(Modifier.height(92.dp))
//            Spacer(Modifier.weight(1f, true))

            Row {
                Text(
                    modifier = Modifier.align(Alignment.CenterVertically),
                    text = "Already have an account?",
                    fontWeight = FontWeight.Medium
                )
                TextButton(onClick = {
                    onLoginClick()
                }) {
                    Text("Sign in")
                }
            }

        }
    }
}

