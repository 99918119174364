
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.location
import org.jetbrains.compose.resources.vectorResource

@Composable
fun ArtistHeader(
    artist: Artist,
    compact: Boolean = false,
) {
    Row {
        Column {
            Column (
                modifier = Modifier
                    .align(Alignment.CenterHorizontally)
                    .padding(top = 32.dp)
                    .fillMaxWidth()
            ) {
                AsyncImage(
                    modifier = Modifier
                        .size(132.dp)
                        .align(Alignment.CenterHorizontally)
                        .clip(CircleShape),
                    model = artist.picture,
                    contentDescription = null,
                    contentScale = ContentScale.Crop
                )

                Column(
                    modifier = Modifier
                        .fillMaxHeight()
                        .align(Alignment.CenterHorizontally)
                        .padding(24.dp)
                ) {
                    Text(
                        modifier = Modifier.align(Alignment.CenterHorizontally),
                        text = artist?.name ?: "",
                        fontSize = if (compact) 20.sp else 32.sp,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center,
                    )
                    if (artist.caption?.isNotEmpty() ?: false) {
                        Text(
                            modifier = Modifier.padding(vertical = 4.dp).align(Alignment.CenterHorizontally),
                            text = artist.caption!!,
                            fontSize = if (compact) 14.sp else 16.sp,
                            textAlign = TextAlign.Center,
                        )
                    }

                    artist.genres?.filter { it != "null" }?.takeIf { it.isNotEmpty() }?.let { genres ->
                        Row(
                            modifier = Modifier.align(Alignment.CenterHorizontally)
                        ) {
                            genres.take(3).forEachIndexed { i, genre ->
                                if (i > 0) {
                                    Text(" / ")
                                }
                                Text(
                                    genre,
                                    fontSize = 12.sp
                                )
                            }
                        }
                    }

                    artist.location?.let {
                        Row(
                            modifier = Modifier.align(Alignment.CenterHorizontally).padding(top = 12.dp)
                        ) {
                            Icon(
                                modifier = Modifier
                                    .size(24.dp)
                                    .align(Alignment.CenterVertically)
                                    .padding(end = 4.dp),
                                imageVector = vectorResource(Res.drawable.location),
                                contentDescription = "Location"
                            )

                            Text(
                                modifier = Modifier
                                    .align(Alignment.CenterVertically),
                                text = artist.location?.name ?: "",
                                fontWeight = FontWeight.SemiBold,
                                fontSize = 14.sp,
                                lineHeight = 14.sp
                            )
                        }
                    }
                }
            }

        }
    }
}
