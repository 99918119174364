
import kotlinx.serialization.Serializable
import util.Location

@Serializable
data class Artist(
    val id: String,
    val editors: List<String>,
    val handle: String,
    val name: String? = null,
    val caption: String? = null,
    val spotify: String? = null,
    val picture: String? = null,
    val location: Location? = null,
    val email: String? = null,
    val facebook: String? = null,
    val instagram: String? = null,
    val tiktok: String? = null,
    val appleMusic: String? = null,
    val topTracks: List<Track>? = null,
    val genres: List<String>? = null,
    val bio: String? = null,
    val createdBy: String? = null,
    val soundsLike: List<SpotifyArtist>? = null,
    val links: List<Link>? = null,
    val images: List<SpotifyImage>? = null,
    val theme: Theme? = null,
)

@Serializable
data class Link(
    val title: String,
    val url: String,
    val image: String,
    val backgroundColor: String,
    val contentColor: String? = null,
)

@Serializable
data class Theme(
    val seedColor: String? = null,
    val style: String? = null,
)

//class ArtistBuilder() {
//    var name: String? = null
//    var picture: String? = null
//    var spotifyId: String? = null
//    var caption: String? = null
//    var locationName: String? = null
//    var location: GeoPoint? = null
//    var bio: String? = null
//    var email: String? = null
//    var facebook: String? = null
//    var instagram: String? = null
//    var tiktok: String? = null
//    var topTracks: List<Track>? = null
//    var genres: List<String>? = null
//
//    fun build() = Artist(
//        name = name!!,
//        picture = picture!!,
//        spotify = spotifyId!!,
//        caption = caption!!,
//        location = this.location!!,
//        locationName = locationName!!,
//        bio = bio!!,
//        email = email!!,
//        facebook = facebook!!,
//        instagram = instagram!!,
//        tiktok = tiktok!!,
//        topTracks = topTracks!!,
//        genres = genres!!,
//        id = "",
//    )
//}