package data

import Log
import kotlinx.coroutines.await
import kotlin.js.Promise

actual class GooglePlaces {

    actual suspend fun autocomplete(query: String): List<PlacesRepository.PlacePrediction> {
        val result = placesAutoComplete(query).await()
        Log().i("Places", result)
        return result.unsafeCast<Array<PlacePrediction>>().toList().map {
            PlacesRepository.PlacePrediction(
                it.place_id,
                it.description
            )
        }
    }

    actual suspend fun getPlaceDetails(id: String): PlacesRepository.Place {
        val result = placeDetails(id).await()
        Log().i("Places", result)
        return result.unsafeCast<Place>().let {
            PlacesRepository.Place(
                name = it.displayName,
                geometry = PlacesRepository.Geometry(
                    location = PlacesRepository.LatLngLiteral(
                        lat = it.location.lat,
                        lng = it.location.lng
                    )
                ),
                country = it.address_components.filter {
                    it.types.contains("country")
                }.firstOrNull()?.long_name,
                region = it.address_components.filter {
                    it.types.contains("administrative_area_level_1")
                }.firstOrNull()?.long_name,
            )
        }
    }
}

external fun placesAutoComplete(query: String): Promise<dynamic>
external fun placeDetails(id: String): Promise<dynamic>

external object PlacePrediction {
    val description: String
    var place_id: String
}

external object Place {
    val displayName: String
    val location: LatLngLiteral
    val address_components: Array<AddressComponent>
}

external object AddressComponent {
    val long_name: String
    val short_name: String
    val types: Array<String>
}

external object Geometry {
    val location: LatLngLiteral
}

external object LatLngLiteral {
    val lat: Double
    val lng: Double
}