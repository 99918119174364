package ui.component

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun LocaTextField(
    modifier: Modifier = Modifier.fillMaxWidth(),
    value: String,
    onValueChange: (String) -> Unit,
    label: String,
    visualTransformation: VisualTransformation = VisualTransformation.None,
    keyboardOptions: KeyboardOptions = KeyboardOptions.Default.copy(
        capitalization = KeyboardCapitalization.Sentences
    ),
    leadingIcon: @Composable (() -> Unit)? = null,
    trailingIcon: @Composable (() -> Unit)? = null,
    trailingButton: @Composable (() -> Unit)? = null,
    isError: Boolean = false,
    keyboardActions: KeyboardActions = KeyboardActions.Default,
    singleLine: Boolean = true,
    errorLabel: String? = null,
    placeholder: String? = null,
    enabled: Boolean = true,
) {
    Column {
        Row {
            OutlinedTextField(
                modifier = modifier,
                value = value,
                onValueChange = onValueChange,
                label = {
                    Text(
                        modifier = Modifier,
//                        .clip(RoundedCornerShape(
//                            8.dp,
//                            8.dp,
//                        ))
//                        .background(MaterialTheme.colorScheme.surfaceContainer)
//                        .padding(
//                            vertical = 4.dp,
//                            horizontal = 8.dp
//                        ),
                        text = label
                    )
                },
                visualTransformation = visualTransformation,
                keyboardOptions = keyboardOptions,
                shape = RoundedCornerShape(12.dp),
                colors = OutlinedTextFieldDefaults.colors().copy(
                    unfocusedContainerColor = MaterialTheme.colorScheme.surfaceContainer,
                    focusedContainerColor = MaterialTheme.colorScheme.surfaceContainer,
                    unfocusedIndicatorColor = MaterialTheme.colorScheme.surfaceContainer,
                    focusedLeadingIconColor = MaterialTheme.colorScheme.primary,
                    errorLeadingIconColor = MaterialTheme.colorScheme.error
                ),
                leadingIcon = leadingIcon,
                trailingIcon = trailingIcon,
                isError = isError,
                keyboardActions = keyboardActions,
                singleLine = singleLine,
                placeholder = placeholder?.let {
                    {
                        Text(placeholder)
                    }
                },
                enabled = enabled,
            )

            Spacer(Modifier.width(12.dp))

            Box(modifier = Modifier.align(Alignment.CenterVertically)) {
                trailingButton?.invoke()
            }
        }

        Spacer(Modifier.height(4.dp))

        Text(
            text = errorLabel ?: "",
            color = MaterialTheme.colorScheme.error,
            maxLines = 1,
            fontSize = 12.sp,
            fontWeight = FontWeight.Medium
        )
    }
}