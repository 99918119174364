package ui.create

import Log
import SpotifyArtist
import Track
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage

@Composable
fun ConfirmBand(
    spotifyArtist: SpotifyArtist,
    topTracks: List<Track>,
    onYes: () -> Unit,
    onNo: () -> Unit,
) {

    val uriHandler = LocalUriHandler.current

    Box(Modifier.fillMaxSize()) {
        Column {

            LazyColumn(Modifier.fillMaxWidth().weight(1f, true)) {

                item {
                    Spacer(Modifier.height(24.dp))
                }

                item {
                    Column(Modifier.fillMaxWidth()) {
                        Box(
                            modifier = Modifier
                                .size(192.dp)
                                .padding(12.dp)
                                .align(Alignment.CenterHorizontally)
                                .clip(CircleShape)
                        ) {
                            AsyncImage(
                                modifier = Modifier,
                                model = spotifyArtist.images.firstOrNull()?.url,
                                contentDescription = null,
                                contentScale = ContentScale.Crop
                            )
                        }

                        Spacer(Modifier.height(24.dp))

                        Text(
                            modifier = Modifier.align(Alignment.CenterHorizontally),
                            text = spotifyArtist.name,
                            fontSize = 18.sp,
                            fontWeight = FontWeight.Bold,
                        )

                        spotifyArtist.genres?.takeIf { it.isNotEmpty() }?.let { genres ->
                            Text(
                                modifier = Modifier.align(Alignment.CenterHorizontally),
                                text = genres.take(3).joinToString(" / "),
                                fontSize = 16.sp
                            )
                        }

                        spotifyArtist.followers?.let { followers ->
                            Text(
                                modifier = Modifier.align(Alignment.CenterHorizontally),
                                text = "${followers.total?.formatWithThousandComma()} followers",
                                fontSize = 14.sp
                            )
                        }

                        Spacer(Modifier.height(24.dp))

                        Text(
                            modifier = Modifier.padding(horizontal = 12.dp),
                            text = "Top Tracks",
                            fontWeight = FontWeight.Bold,
                            fontSize = 16.sp
                        )

                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(top = 12.dp)
                                .clip(RoundedCornerShape(24.dp))
                                .background(MaterialTheme.colorScheme.surfaceContainer)
//                    .clickable {
//                        uriHandler.openUri("https://open.spotify.com/artist/${spotifyArtist.id}")
//                    }
                                .padding(horizontal = 18.dp, vertical = 18.dp)
                        ) {
                            Box(modifier = Modifier.fillMaxWidth()) {

                                Column {
                                    topTracks.take(3)
                                        .forEachIndexed { i, track ->
                                            Log().i("TRACK", track.toString())
                                            Row {
                                                Box(
                                                    modifier = Modifier
                                                        .size(72.dp)
                                                        .padding(12.dp)
                                                        .align(Alignment.CenterVertically)
                                                        .clip(RoundedCornerShape(12.dp))
                                                ) {
                                                    AsyncImage(
                                                        modifier = Modifier,
                                                        model = track.album?.images?.firstOrNull()?.url,
                                                        contentDescription = track.name,
                                                        contentScale = ContentScale.Crop
                                                    )
                                                }
                                                Text(
                                                    modifier = Modifier
                                                        .weight(1f, fill = true)
                                                        .padding(start = 4.dp)
                                                        .align(Alignment.CenterVertically),
                                                    text = track.name,
                                                    fontWeight = FontWeight.Medium
                                                )
                                            }
                                        }
                                }
                            }
                        }
                    }
                }
            }


            Spacer(Modifier.height(24.dp))

            Button(
                modifier = Modifier.fillMaxWidth(),
                onClick = { onYes() },
                shape = CircleShape,
            ) {
                Text(
                    modifier = Modifier.padding(vertical = 12.dp),
                    text = "Confirm"
                )
            }

            TextButton(
                modifier = Modifier.fillMaxWidth(),
                onClick = { onNo() },
                colors = ButtonDefaults.textButtonColors(
                    contentColor = MaterialTheme.colorScheme.error
                )
            ) {
                Text(
                    modifier = Modifier.padding(vertical = 12.dp),
                    text = "This isn't me"
                )
            }


            Spacer(Modifier.height(24.dp))
        }
    }
}