package ui.component

import Artist
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage

@Composable
fun ArtistCell(artist: Artist) {

    val uriHandler = LocalUriHandler.current

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 24.dp)
    ) {
        AsyncImage(
            modifier = Modifier
                .size(72.dp)
                .align(Alignment.CenterVertically)
                .clip(CircleShape),
            model = artist.picture,
            contentDescription = null,
            contentScale = ContentScale.Crop
        )

        Column(
            modifier = Modifier
                .padding(horizontal = 24.dp, vertical = 32.dp)
        ) {
            Text(
                modifier = Modifier,
                text = artist.name ?: "@${artist.handle}",
                fontSize = 20.sp,
                fontWeight = FontWeight.Bold,
            )

            if (artist.handle?.isNotEmpty() ?: false) {
                Text(
                    modifier = Modifier.padding(top = 4.dp),
                    text = "@${artist.handle}",
                    fontSize = 14.sp,
                    fontWeight = FontWeight.Medium
                )
            }

//            artist.genres?.let { genres ->
//                Row(
//                    modifier = Modifier.align(Alignment.CenterHorizontally)
//                ) {
//                    genres.take(3).forEachIndexed { i, genre ->
//                        if (i > 0) {
//                            Text(" / ")
//                        }
//                        Text(genre.capitalize(Locale.current))
//                    }
//                }
//            }

//            OutlinedButton(
//                modifier = Modifier.padding(top = 12.dp),
//                onClick = { uriHandler.openUri("https://secret.loca.fm/@${artist.handle}") },
//                shape = CircleShape
//            ) {
//                Text("https://loca.fm/@${artist.handle}")
//            }

//            artist.location?.let {
//                Row(
//                    modifier = Modifier
//                ) {
//                    Icon(
//                        modifier = Modifier
//                            .size(24.dp)
//                            .align(Alignment.CenterVertically)
//                            .padding(end = 4.dp),
//                        imageVector = vectorResource(Res.drawable.location),
//                        contentDescription = "Location"
//                    )
//
//                    Text(
//                        modifier = Modifier
//                            .align(Alignment.CenterVertically),
//                        text = artist.location?.name ?: "",
//                        fontWeight = FontWeight.SemiBold,
//                        fontSize = 14.sp,
//                        lineHeight = 14.sp,
//                    )
//                }
//            }
        }
    }
}