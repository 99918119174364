package ui.create

import SpotifyArtist
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.ElevatedSuggestionChip
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.runtime.toMutableStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.close
import loca.composeapp.generated.resources.search
import org.jetbrains.compose.resources.vectorResource
import ui.component.LocaTextField

@Composable
fun PickGenres(
    selectedGenres: SnapshotStateList<String>,
    soundsLike: List<SpotifyArtist>,
    onClose: () -> Unit,
) {
    val allGenres = listOf(
        "acoustic",
        "afrobeat",
        "alt-rock",
        "alternative",
        "ambient",
        "anime",
        "black-metal",
        "bluegrass",
        "blues",
        "bossanova",
        "brazil",
        "breakbeat",
        "british",
        "cantopop",
        "chicago-house",
        "children",
        "chill",
        "classical",
        "club",
        "comedy",
        "country",
        "dance",
        "dancehall",
        "death-metal",
        "deep-house",
        "detroit-techno",
        "disco",
        "disney",
        "drum-and-bass",
        "dub",
        "dubstep",
        "edm",
        "electro",
        "electronic",
        "emo",
        "folk",
        "forro",
        "french",
        "funk",
        "garage",
        "german",
        "gospel",
        "goth",
        "grindcore",
        "groove",
        "grunge",
        "guitar",
        "happy",
        "hard-rock",
        "hardcore",
        "hardstyle",
        "heavy-metal",
        "hip-hop",
        "holidays",
        "honky-tonk",
        "house",
        "idm",
        "indian",
        "indie",
        "indie-pop",
        "industrial",
        "iranian",
        "j-dance",
        "j-idol",
        "j-pop",
        "j-rock",
        "jazz",
        "k-pop",
        "kids",
        "latin",
        "latino",
        "malay",
        "mandopop",
        "metal",
        "metal-misc",
        "metalcore",
        "minimal-techno",
        "movies",
        "mpb",
        "new-age",
        "new-release",
        "opera",
        "pagode",
        "party",
        "philippines-opm",
        "piano",
        "pop",
        "pop-film",
        "post-dubstep",
        "power-pop",
        "progressive-house",
        "psych-rock",
        "punk",
        "punk-rock",
        "r-n-b",
        "rainy-day",
        "reggae",
        "reggaeton",
        "road-trip",
        "rock",
        "rock-n-roll",
        "rockabilly",
        "romance",
        "sad",
        "salsa",
        "samba",
        "sertanejo",
        "show-tunes",
        "singer-songwriter",
        "ska",
        "sleep",
        "songwriter",
        "soul",
        "soundtracks",
        "spanish",
        "study",
        "summer",
        "swedish",
        "synth-pop",
        "tango",
        "techno",
        "trance",
        "trip-hop",
        "turkish",
        "work-out",
        "world-music"
    )

    val genres = remember { allGenres.toMutableStateList() }

    var query by remember { mutableStateOf("") }

    Box(Modifier.fillMaxSize().padding(horizontal = 24.dp)) {
        Column {

            Spacer(Modifier.height(48.dp))

            Text(
                modifier = Modifier.align(Alignment.CenterHorizontally),
                text = "What genres best match your music?",
                fontSize = 20.sp,
                fontWeight = FontWeight.Bold,
                textAlign = TextAlign.Center,
            )

            Text(
                modifier = Modifier.align(Alignment.CenterHorizontally).padding(top = 4.dp),
                text = "This helps like-minded fans find you",
                fontSize = 14.sp,
                textAlign = TextAlign.Center
            )

            Spacer(Modifier.height(24.dp))

            LocaTextField(
                value = query,
                onValueChange = {
                    query = it
                    if (query == "") {
                        genres.clear()
                        genres.addAll(allGenres)
                    } else {
                        genres.clear()
                        genres.addAll(allGenres.filter {
                            it.contains(query, true)
                        })
                    }
                },
                label = "Search Genres",
                leadingIcon = {
                    Icon(
                        imageVector = vectorResource(Res.drawable.search),
                        contentDescription = "Search"
                    )
                },
                keyboardOptions = KeyboardOptions.Default.copy(
                    capitalization = KeyboardCapitalization.Words
                )
            )

            AnimatedVisibility(selectedGenres.isEmpty()) {
                TextButton(
                    modifier = Modifier.fillMaxWidth(),
                    onClick = { onClose() },
                    shape = RoundedCornerShape(24.dp)
                ) {
                    Text("Continue without genres")
                }
                Spacer(Modifier.height(12.dp))
            }

            AnimatedVisibility(selectedGenres.isNotEmpty()) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                ) {
                    LazyRow {
                        items(selectedGenres) { genre ->
                            Row (
                                modifier = Modifier
                                    .clip(CircleShape)
                                    .clickable { selectedGenres.remove(genre) }
                                    .border(1.dp, MaterialTheme.colorScheme.primary, CircleShape)
                                    .padding(vertical = 4.dp),
                            ) {
                                Spacer(Modifier.width(12.dp))
                                Text(
                                    modifier = Modifier
//                                        .align(Alignment.CenterVertically)
                                        .padding(vertical = 8.dp),
                                    text = genre,
                                    fontWeight = FontWeight.Medium,
                                    fontSize = 16.sp,
                                    color = MaterialTheme.colorScheme.primary
                                )
                                Spacer(Modifier.width(4.dp))
                                Icon(
                                    modifier = Modifier.align(Alignment.CenterVertically),
                                    imageVector = vectorResource(Res.drawable.close),
                                    contentDescription = "Remove",
                                    tint = MaterialTheme.colorScheme.primary,
                                )
                                Spacer(Modifier.width(12.dp))
                            }
                            Spacer(Modifier.width(12.dp))
                        }
                        item { Spacer(Modifier.width(12.dp)) }
                    }

                    Spacer(Modifier.height(24.dp))

                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .clip(CircleShape)
                            .background(MaterialTheme.colorScheme.primary)
                            .clickable { onClose() }
                            .padding(12.dp),
                    ) {
                        Text(
                            modifier = Modifier.align(Alignment.CenterHorizontally),
                            text = "Confirm",
                            color = MaterialTheme.colorScheme.surfaceContainer,
                            fontWeight = FontWeight.SemiBold
                        )
                    }
                }
            }

//                selectedGenres.forEach { genre ->
//                    Row(
//                        modifier = Modifier
//                            .fillMaxWidth()
//                            .padding(vertical = 8.dp)
////                            .clip(CircleShape)
////                            .background(MaterialTheme.colorScheme.surfaceContainer)
//                            .padding(start = 24.dp, end = 12.dp)
//                            .padding(vertical = 8.dp)
//                    ) {
//                        Text(
//                            modifier = Modifier.align(Alignment.CenterVertically),
//                            text = genre,
//                            fontSize = 16.sp,
//                            fontWeight = FontWeight.Medium
//                        )
//                        Spacer(Modifier.weight(1f, true))
//                        IconButton(onClick = {
//                            selectedGenres.remove(genre)
//                        }) {
//                            Icon(
//                                imageVector = vectorResource(Res.drawable.close),
//                                contentDescription = "Remove"
//                            )
//                        }
//                    }

            AnimatedVisibility(selectedGenres.size < 3) {
                LazyColumn(

                ) {

                    item { Spacer(Modifier.height(12.dp)) }

                    items(soundsLike) { artist ->
                        artist.genres
                            ?.filter { !selectedGenres.contains(it) && it.contains(query) }
                            ?.takeIf { it.isNotEmpty() }
                            ?.let { genres ->
                                Column(
                                    Modifier
                                        .fillMaxWidth()
                                        .clip(RoundedCornerShape(24.dp))
                                        .background(MaterialTheme.colorScheme.surfaceContainer)
                                ) {
                                    Text(
                                        modifier = Modifier
                                            .padding(
                                                horizontal = 24.dp,
                                                vertical = 12.dp
                                            ),
                                        text = artist.name,
                                        fontWeight = FontWeight.SemiBold
                                    )
                                    LazyRow {
                                        item { Spacer(Modifier.width(24.dp)) }
                                        items(genres) { genre ->
                                            ElevatedSuggestionChip(
                                                onClick = { selectedGenres.add(genre) },
                                                label = {
                                                    Text(
                                                        text = genre,
                                                        fontWeight = FontWeight.Medium
                                                    )
                                                }
                                            )
                                            Spacer(Modifier.width(12.dp))
                                        }
                                        item { Spacer(Modifier.width(12.dp)) }
                                    }
                                    Spacer(Modifier.height(12.dp))
                                }
                                Spacer(Modifier.height(12.dp))
                            }
//                        Box(Modifier.weight(1f, true)) {
//                            Row(
//                                modifier = Modifier
//                                    .fillMaxWidth()
//                                    .align(Alignment.Center)
//                                    .clip(RoundedCornerShape(12.dp))
//                                    .clickable {
//                                        selectedGenres.add(it)
//                                    }
//                                    .padding(horizontal = 18.dp, vertical = 18.dp)
//
//                            ) {
//                                Text(
//                                    modifier = Modifier,
//                                    text = it,
//                                    fontSize = 14.sp,
//                                    fontWeight = FontWeight.Medium,
//                                    textAlign = TextAlign.Center,
//                                )
//                            }
//                        }
                    }

                    items(genres.filter { !selectedGenres.contains(it) }) {
                        Box(
                            Modifier.fillMaxWidth()
                        ) {
                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .align(Alignment.Center)
                                    .clip(RoundedCornerShape(12.dp))
                                    .clickable {
                                        selectedGenres.add(it)
                                    }
                                    .padding(horizontal = 18.dp, vertical = 18.dp)

                            ) {
                                Text(
                                    modifier = Modifier,
                                    text = it,
                                    fontSize = 14.sp,
                                    fontWeight = FontWeight.Medium,
                                    textAlign = TextAlign.Center,
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}
