package ui.create

import SpotifyArtist
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import data.LocalSpotify
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.close
import loca.composeapp.generated.resources.search
import org.jetbrains.compose.resources.vectorResource
import ui.component.LocaTextField

@Composable
fun SoundLike(
    selected: List<SpotifyArtist>,
    onSelection: (List<SpotifyArtist>) -> Unit
) {
    val spotifyRepository = LocalSpotify.current
    var query by remember { mutableStateOf("") }

    var autoCompleteJob: Job? = null
    val soundsLike = remember { mutableStateListOf<SpotifyArtist>().apply { addAll(selected) } }
    val spotifyArtists = remember { mutableStateListOf<SpotifyArtist>() }

    var loading by remember { mutableStateOf(false) }

    val coroutineScope = rememberCoroutineScope(
        getContext = { Dispatchers.Unconfined }
    )

    Box(
        modifier = Modifier
            .fillMaxSize()
            .padding(horizontal = 24.dp),
    ) {
        Column(
            modifier = Modifier.align(Alignment.Center).fillMaxHeight()
        ) {
            Spacer(Modifier.height(48.dp))

            Text(
                modifier = Modifier.align(Alignment.CenterHorizontally),
                text = "What artists do you sound like?",
                fontSize = 20.sp,
                fontWeight = FontWeight.Bold,
            )

            Text(
                modifier = Modifier.align(Alignment.CenterHorizontally).padding(top = 4.dp),
                text = "This helps like-minded fans find you",
                fontSize = 14.sp
            )

            Spacer(Modifier.height(24.dp))

            LocaTextField(
                modifier = Modifier
                    .fillMaxWidth()
                    .align(Alignment.CenterHorizontally),
                value = query,
                onValueChange = {
                    query = it

                    autoCompleteJob = coroutineScope.launch {
                        if (query != "") {
                            loading = true
                            val toSearch = query
                            autoCompleteJob?.cancel()
                            spotifyArtists.clear()
                            delay(1500)
                            if (query != "" && query == toSearch) {
                                val artists = spotifyRepository.searchArtists(query)
                                if (query == toSearch) {
                                    spotifyArtists.addAll(artists)
                                    loading = false
                                }
                            }
                        } else {
                            loading = false
                        }
                    }
                },
                label = "Search",
                leadingIcon = {
                    Icon(
                        imageVector = vectorResource(Res.drawable.search),
                        contentDescription = "Search"
                    )
                },
                trailingIcon = {
                    if (loading) {
                        CircularProgressIndicator(
                            Modifier.size(24.dp)
                        )
                    }
                },
                enabled = soundsLike.size < 3,
            )

            AnimatedVisibility(soundsLike.isEmpty()) {
                TextButton(
                    modifier = Modifier.fillMaxWidth(),
                    onClick = { onSelection(listOf()) },
                    shape = RoundedCornerShape(24.dp)
                ) {
                    Text("Continue without similar artists")
                }
                Spacer(Modifier.height(12.dp))
            }

            AnimatedVisibility(soundsLike.isNotEmpty()) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .clip(RoundedCornerShape(24.dp))
                        .background(MaterialTheme.colorScheme.surfaceContainer)
                ) {
                    Text(
                        modifier = Modifier.padding(horizontal = 24.dp, vertical = 12.dp),
                        text = "Selected",
                        fontWeight = FontWeight.SemiBold,
                        fontSize = 18.sp,
                    )
                    LazyRow {
                        item { Spacer(Modifier.width(24.dp)) }
                        items(soundsLike) { artist ->
                            Row(
                                modifier = Modifier
                                    .clip(CircleShape)
                                    .background(MaterialTheme.colorScheme.primary.copy(alpha = 0.75f))
                                    .clickable { soundsLike.remove(artist) },
                            ) {
                                AsyncImage(
                                    modifier = Modifier
                                        .size(48.dp)
                                        .clip(CircleShape),
                                    model = artist.images.firstOrNull()?.url,
                                    contentDescription = artist.name
                                )
                                Spacer(Modifier.width(8.dp))
                                Text(
                                    modifier = Modifier
                                        .align(Alignment.CenterVertically),
                                    text = artist.name,
                                    fontWeight = FontWeight.Medium,
                                    fontSize = 16.sp,
                                )
                                Spacer(Modifier.width(4.dp))
                                Icon(
                                    modifier = Modifier.align(Alignment.CenterVertically)
                                        .alpha(0.75f),
                                    imageVector = vectorResource(Res.drawable.close),
                                    contentDescription = "Remove"
                                )
                                Spacer(Modifier.width(8.dp))
                            }
                            Spacer(Modifier.width(12.dp))
                        }
                        item { Spacer(Modifier.width(12.dp)) }
                    }

                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(12.dp)
                            .clip(RoundedCornerShape(12.dp))
                            .background(MaterialTheme.colorScheme.primary)
                            .clickable { onSelection(soundsLike) }
                            .padding(12.dp),
                    ) {
                        Text(
                            modifier = Modifier.align(Alignment.CenterHorizontally),
                            text = "Confirm",
                            color = MaterialTheme.colorScheme.surfaceContainer,
                            fontWeight = FontWeight.SemiBold
                        )
                    }
                }
            }

            LazyColumn (
                modifier = Modifier
                    .fillMaxWidth()
                    .weight(1f, true)
            ) {

                items(spotifyArtists) {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .clip(RoundedCornerShape(24.dp))
                            .clickable {
                                coroutineScope.launch {
                                    query = ""
                                    spotifyArtists.clear()
                                    soundsLike.add(it)
                                }
                            }
                            .padding(24.dp)
                    ) {
                        AsyncImage(
                            modifier = Modifier
                                .size(72.dp)
                                .clip(CircleShape)
                                .align(Alignment.CenterVertically),
                            model = it.images.firstOrNull()?.url ?: "",
                            contentDescription = "Artist Picture"
                        )

                        Spacer(Modifier.width(12.dp))

                        Column(
                            modifier = Modifier.align(Alignment.CenterVertically),
                        ) {
                            Text(
                                text = it.name,
                                fontWeight = FontWeight.Medium,
                                fontSize = 16.sp
                            )

                            it.genres?.takeIf { it.isNotEmpty() }?.let { genres ->
                                Text(
                                    text = genres.take(3).joinToString(" / "),
                                    fontSize = 14.sp
                                )
                            }

                            it.followers?.let { followers ->
                                Text(
                                    text = "${followers.total?.formatWithThousandComma()} followers",
                                    fontSize = 12.sp
                                )
                            }

                            Text(
                                text = "https://spotify.com/artists/${it.id}",
                                fontSize = 12.sp,
                                color = MaterialTheme.colorScheme.onBackground.copy(alpha = 0.5f)
                            )
                        }
                    }
                }
            }
        }
    }
}
