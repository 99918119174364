package data

import Ads
import AudioPlayer
import LocaNavigator
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.runtime.mutableStateOf
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.firestore.GeoPoint
import dev.gitlive.firebase.firestore.firestore
import util.Location

private val http = LocaHttp()
val LocalSpotify = compositionLocalOf { SpotifyRepository(http) }
val LocalSpotifyPlayer = compositionLocalOf { mutableStateOf<SpotifyPlayer?>(null) }
val LocalSpotifyPlayerState = compositionLocalOf { mutableStateOf<SpotifyPlayerState?>(null) }
val LocalPlaces = compositionLocalOf { PlacesRepository(http) }
val LocalFirestore = compositionLocalOf { LocaFirestore(Firebase.firestore) }
val LocalAudioPlayer = compositionLocalOf { AudioPlayer() }
val LocalLocation = compositionLocalOf { mutableStateOf<Location?>(Location("Brisbane", GeoPoint(-27.470125, 153.021072))) }
val LocalAds = compositionLocalOf { mutableStateOf<Ads?>(null) }
val LocaNavigator = compositionLocalOf { mutableStateOf<LocaNavigator?>(null) }
val LocalAnalytics = compositionLocalOf { Analytics() }

// Location("Brisbane", GeoPoint(-27.470125, 153.021072))