package data

import dev.gitlive.firebase.firestore.GeoPoint
import io.ktor.client.call.body
import io.ktor.client.request.parameter
import io.ktor.http.HttpMethod
import kotlinx.serialization.Serializable

private const val API_KEY = "AIzaSyAOGz1wBV9JsIpIKMM52hiEE4BrqtWCvq0"

class PlacesRepository(private val client: LocaHttp) {

    @Serializable
    data class PlacePrediction(
        val place_id: String,
        val description: String,
    )

    @Serializable
    data class PlaceAutoCompleteResponse(
        val suggestions: List<PlacePrediction>
    )

    @Serializable
    data class LatLngLiteral(
        val lat: Double,
        val lng: Double,
    )

    @Serializable
    data class Geometry(
        val location: LatLngLiteral
    )

    @Serializable
    data class Place(
        val name: String,
        val geometry: Geometry,
        val country: String? = null,
        val region: String? = null,
    )

    @Serializable
    data class PlaceDetailsResponse(
        val result: Place
    )

    @Serializable
    data class CurrentLocationResponse(
        val latitude: Double,
        val longitude: Double,
    )

    suspend fun autocomplete(value: String) =
        client.request("https://maps.googleapis.com/maps/api/place/autocomplete/json") {
            method = HttpMethod.Get
            parameter("input", value)
            parameter("types", "(cities)")
            parameter("key", API_KEY)
        }.body<PlaceAutoCompleteResponse>().suggestions

    suspend fun placeDetails(id: String) =
        client.request("https://maps.googleapis.com/maps/api/place/details/json") {
            method = HttpMethod.Get
            parameter("place_id", id)
            parameter("fields", "name,geometry")
            parameter("key", API_KEY)
        }.body<PlaceDetailsResponse>().result

    suspend fun currentLocation(): GeoPoint =
        client.request("https://ipwho.is/") {
            method = HttpMethod.Get
        }.body<CurrentLocationResponse>().let { response ->
            GeoPoint(response.latitude, response.longitude)
        }

}