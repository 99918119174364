package ui.create

import Artist
import Theme
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.ColorScheme
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.annotation.ExperimentalCoilApi
import coil3.compose.AsyncImage
import com.github.ajalt.colormath.extensions.android.composecolor.toColormathColor
import com.materialkolor.PaletteStyle
import com.materialkolor.dynamicColorScheme
import com.materialkolor.ktx.hasEnoughContrast
import com.materialkolor.ktx.themeColor
import org.jetbrains.skia.Bitmap
import util.ImageUtil

@OptIn(ExperimentalCoilApi::class)
@Composable
fun ThemePicker(
    artist: Artist,
    colorScheme: ColorScheme,
    onColorsChange: (ColorScheme) -> Unit,
    onConfirm: (Theme) -> Unit,
) {

    var seedColor by remember { mutableStateOf<Color?>(null) }
    var style by remember { mutableStateOf("subtle") }

    var colors by remember { mutableStateOf(colorScheme) }
    val boldColors = colors.let {
        it.copy(
            background = seedColor ?: Color.Black,
            onBackground = it.onPrimaryContainer,
        )
    }

    LaunchedEffect(colors) {
        when (style) {
            "bold" -> onColorsChange(boldColors)
            else -> onColorsChange(colors)
        }
    }

    LaunchedEffect(seedColor) {
        seedColor?.let { color ->
            val isDark = color.hasEnoughContrast(Color.White)
            colors = dynamicColorScheme(
                seedColor = color,
                isDark = isDark,
                isAmoled = false,
                style = PaletteStyle.Vibrant,
                isExtendedFidelity = true,
            )
        }
    }

    LaunchedEffect(style) {
        val value = when (style) {
            "subtle" -> colors
            "bold" -> boldColors
            else -> colors
        }
        onColorsChange(value)
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .padding(horizontal = 24.dp),
    ) {
        Column(
            Modifier.fillMaxHeight()
        ) {

            Spacer(Modifier.height(48.dp))

            Text(
                modifier = Modifier.align(Alignment.CenterHorizontally),
                text = "Pick an image for your theme",
                fontSize = 20.sp,
                fontWeight = FontWeight.Bold,
            )

            Text(
                modifier = Modifier.align(Alignment.CenterHorizontally).padding(top = 4.dp),
                text = "We base your colour scheme on this",
                fontSize = 14.sp
            )

//            Column(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .clip(RoundedCornerShape(24.dp))
//                    .background(MaterialTheme.colorScheme.surfaceContainer)
//                    .padding(24.dp)
//            ) {

            Spacer(Modifier.height(48.dp))

            LazyRow {
                    item {
                        var image by remember { mutableStateOf<Bitmap?>(null) }
                        Box(Modifier
                            .size(128.dp)
                            .clip(RoundedCornerShape(12.dp))
                            .clickable {
                                image?.let {
                                    seedColor = ImageUtil().imageBitmapFromBitmap(it)
                                        ?.themeColor(fallback = Color.Black)
                                }
                            }
                        ) {
                            AsyncImage(
                                model = artist.picture,
                                contentDescription = "Display Picture",
                                contentScale = ContentScale.Crop,
                                onSuccess = {
                                    image = it.result.image.toBitmap()
                                }
                            )
                        }
                        Spacer(Modifier.width(12.dp))
                    }

                    items(artist.topTracks?.map {
                        it.album?.images?.firstOrNull()?.url
                    }?.distinct() ?: listOf()) { url ->
                        var image by remember { mutableStateOf<Bitmap?>(null) }
                        Box(Modifier
                            .size(128.dp)
                            .clip(RoundedCornerShape(24.dp))
                            .clickable {
                                image?.let {
                                    seedColor = ImageUtil().imageBitmapFromBitmap(it)
                                        ?.themeColor(fallback = Color.Black)
                                }
                            }
                        ) {
                            AsyncImage(
                                model = url ?: "",
                                contentDescription = "Album Cover",
                                contentScale = ContentScale.Crop,
                                onSuccess = {
                                    image = it.result.image.toBitmap()
                                }
                            )
                        }
                        Spacer(Modifier.width(12.dp))
                    }
                }
//                }


//                Text(
//                    text = "Background",
//                    fontWeight = FontWeight.SemiBold
//                )
//                Spacer(Modifier.height(12.dp))
//                backgrounds.chunked(3).forEach { row ->
//                    Row(Modifier.align(Alignment.CenterHorizontally)) {
//                        row.forEachIndexed { i, color ->
//                            Box(
//                                modifier = Modifier
//                                    .size(72.dp)
//                                    .clip(CircleShape)
//                                    .background(color)
//                                    .clickable { seedColor = color }
//                            )
//                            if (i != backgrounds.size - 1) {
//                                Spacer(Modifier.width(12.dp))
//                            }
//                        }
//                    }
//                    Spacer(Modifier.height(12.dp))
//                }
//            }

            Spacer(Modifier.height(12.dp))

            Row {
                OutlinedButton(
                    modifier = Modifier
                        .weight(1f, true),
                    onClick = { style = "subtle" },
                    colors = ButtonDefaults.outlinedButtonColors(
                        containerColor = if (style == "subtle") MaterialTheme.colorScheme.primaryContainer else MaterialTheme.colorScheme.surfaceContainer,
                        contentColor = if (style == "subtle") MaterialTheme.colorScheme.onPrimaryContainer else MaterialTheme.colorScheme.onSurface,
                    )
                ) {
                    Text("Subtle")
                }

                Spacer(Modifier.width(12.dp))

                OutlinedButton(
                    modifier = Modifier
                        .weight(1f, true),
                    onClick = { style = "bold" },
                    colors = ButtonDefaults.outlinedButtonColors(
                        containerColor = if (style == "bold") MaterialTheme.colorScheme.primaryContainer else MaterialTheme.colorScheme.surfaceContainer,
                        contentColor = if (style == "bold") MaterialTheme.colorScheme.onPrimaryContainer else MaterialTheme.colorScheme.onSurface,
                    )
                ) {
                    Text("Bold")
                }
            }

            Spacer(Modifier.height(12.dp))


            Button(
                modifier = Modifier.fillMaxWidth(),
                onClick = { onConfirm(Theme(seedColor?.toColormathColor()?.toSRGB()?.toHex(), style)) },
                shape = CircleShape,
            ) {
                Text(
                    modifier = Modifier.padding(vertical = 12.dp),
                    text = "Confirm"
                )
            }



//            Column(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .clip(RoundedCornerShape(24.dp))
//                    .background(MaterialTheme.colorScheme.surfaceContainer)
//                    .padding(24.dp)
//            ) {
//                Text(
//                    text = "Accent",
//                    fontWeight = FontWeight.SemiBold
//                )
//                Spacer(Modifier.height(12.dp))
//
//                accents.chunked(3).forEach { row ->
//                    Row(Modifier.align(Alignment.CenterHorizontally)) {
//                        row.forEachIndexed { i, color ->
//                            Box(
//                                modifier = Modifier
//                                    .size(72.dp)
//                                    .clip(CircleShape)
//                                    .clip(RoundedCornerShape(12.dp))
//                                    .background(color)
//                                    .clickable { colors = colors.copy(primary = color) }
//                            )
//                            if (i != accents.size - 1) {
//                                Spacer(Modifier.width(12.dp))
//                            }
//                        }
//                    }
//                    Spacer(Modifier.height(12.dp))
//                }
//            }
        }
    }
}