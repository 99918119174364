
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.gestures.snapping.rememberSnapFlingBehavior
import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.navigationBarsPadding
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.ColorScheme
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.blur
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import data.Event
import data.LocalAnalytics
import data.LocalAudioPlayer
import data.LocalFirestore
import data.LocalSpotifyPlayer
import data.LocalSpotifyPlayerState
import io.ktor.http.Url
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.apple_logo_black
import loca.composeapp.generated.resources.facebook
import loca.composeapp.generated.resources.instagram
import loca.composeapp.generated.resources.logo
import loca.composeapp.generated.resources.mail
import loca.composeapp.generated.resources.muted
import loca.composeapp.generated.resources.share
import loca.composeapp.generated.resources.spotify
import loca.composeapp.generated.resources.tiktok
import org.jetbrains.compose.resources.vectorResource
import ui.component.PlayingAnimation
import ui.create.toColorScheme

private const val TAG = "ArtistPage"

@Composable
fun FetchArtistPage(
    id: String? = null,
    username: String? = null,
    onArtistFetch: (Artist?) -> Unit,
    onColorsChanged: (ColorScheme) -> Unit,
) {

//    override val path: String = id ?: username ?: "artist"


//    @Composable
//    override fun TopBar() {
//        val audioPlayer = LocalAudioPlayer.current
//        val navigator = LocalNavigator.currentOrThrow
//        val firestore = LocalFirestore.current
//        var artist by remember { mutableStateOf<Artist?>(null) }
//
//        LaunchedEffect(true) {
//            if (id != null) {
//                artist = firestore.getArtistFromCache(id)
//            } else if (username != null) {
//                artist = firestore.getArtistByUsername(username)
//            }
//        }
//
//        Row(modifier = Modifier.padding(start = 4.dp, end = 8.dp)) {
//            if (id != null) {
//                IconButton(onClick = {
//                    audioPlayer.stopAudio()
//                    navigator.pop()
//                }) {
//                    Icon(
//                        modifier = Modifier
//                            .size(32.dp)
//                            .align(Alignment.CenterVertically),
//                        imageVector = vectorResource(Res.drawable.arrow_left),
//                        contentDescription = "Back"
//                    )
//                }
//            } else {
//                Icon(
//                    modifier = Modifier
//                        .height(48.dp)
//                        .padding(horizontal = 4.dp)
//                        .align(Alignment.CenterVertically)
//                        .clip(RoundedCornerShape(12.dp))
//                        .clickable {
//
//                        },
//                    imageVector = vectorResource(Res.drawable.logo),
//                    contentDescription = "Loca"
//                )
//            }
//
//            Spacer(Modifier.weight(1f, true))
//
//            IconButton(onClick = {
//                audioPlayer.stopAudio()
//                navigator.push(EditArtistScreen(artist))
//            }) {
//                Icon(
//                    modifier = Modifier
//                        .size(32.dp)
//                        .padding(4.dp)
//                        .align(Alignment.CenterVertically),
//                    imageVector = vectorResource(Res.drawable.edit),
//                    contentDescription = "Edit"
//                )
//            }
//
//            IconButton(onClick = {
//                audioPlayer.stopAudio()
//                navigator.push(ShareCodeScreen(artist?.id ?: ""))
//            }) {
//                Icon(
//                    modifier = Modifier
//                        .size(32.dp)
//                        .padding(4.dp)
//                        .align(Alignment.CenterVertically),
//                    imageVector = vectorResource(Res.drawable.share),
//                    contentDescription = "Share"
//                )
//            }
//        }
//    }
    val firestore = LocalFirestore.current
    var artist by remember { mutableStateOf<Artist?>(null) }

    val analytics = LocalAnalytics.current
    val scrollState = rememberLazyListState()
    val atTop = scrollState.firstVisibleItemIndex == 0 && scrollState.firstVisibleItemScrollOffset == 0
    val topBarBackground by animateFloatAsState(if (!atTop)  0.75f else 0f)

    LaunchedEffect(true) {

        analytics.log(Event.PageView(mapOf(
            "screen_name" to "ArtistPage",
            "handle" to (username ?: "null"),
            "id" to (id ?: "null")
        )))

        if (id != null) {
            artist = firestore.getArtistFromCache(id)
            onArtistFetch(artist)
        } else if (username != null) {
            artist = firestore.getArtistByUsername(username)
            onArtistFetch(artist)
        }
    }

    AnimatedVisibility(
        visible = artist != null,
        enter = fadeIn(),
        exit = fadeOut(),
    ) {
        artist?.let {
            val uriHandler = LocalUriHandler.current
            Column {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .background(MaterialTheme.colorScheme.surfaceContainer.copy(alpha = topBarBackground))
                        .padding(12.dp)
                ) {
                    Box(Modifier.align(Alignment.CenterVertically)) {
                        androidx.compose.animation.AnimatedVisibility(
                            visible = atTop,
                            enter = fadeIn(),
                            exit = fadeOut()
                        ) {
                            Icon(
                                modifier = Modifier
                                    .height(48.dp)
                                    .clip(CircleShape)
                                    .clickable { uriHandler.openUri("https://secret.loca.fm") },
                                imageVector = vectorResource(Res.drawable.logo),
                                contentDescription = "Loca"
                            )
                        }

                        androidx.compose.animation.AnimatedVisibility(
                            visible = !atTop,
                            enter = fadeIn(),
                            exit = fadeOut(),
                            modifier = Modifier
                        ) {
                            Row(Modifier.height(48.dp)) {

                                Spacer(Modifier.width(4.dp))

                                AsyncImage(
                                    modifier = Modifier
                                        .size(42.dp)
                                        .align(Alignment.CenterVertically)
                                        .clip(CircleShape),
                                    model = artist?.picture,
                                    contentDescription = null,
                                    contentScale = ContentScale.Crop
                                )

                                Spacer(Modifier.width(12.dp))

                                Text(
                                    modifier = Modifier
                                        .align(Alignment.CenterVertically),
                                    text = artist?.name ?: "",
                                    fontSize = 20.sp,
                                    fontWeight = FontWeight.Bold,
                                )
                            }
                        }
                    }

                    Spacer(Modifier.weight(1f, true))

                    IconButton(
                        modifier = Modifier.align(Alignment.CenterVertically),
                        onClick = {  }
                    ) {
                        Icon(
                            imageVector = vectorResource(Res.drawable.share),
                            contentDescription = "Share"
                        )
                    }
                }
                ArtistPage(it, scrollState, onColorsChanged)
            }
        }
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun ArtistPage(
    artist: Artist,
    listState: LazyListState = rememberLazyListState(),
    onColorsChanged: (ColorScheme) -> Unit,
) {

    val uriHandler = LocalUriHandler.current
    val audioPlayer = LocalAudioPlayer.current
    val spotifyPlayer = LocalSpotifyPlayer.current.value

    val spotifyPlayerState = LocalSpotifyPlayerState.current

    val shows = listOf<Show>()

    var trackProgress by remember { mutableStateOf(0.0) }
    val animatedProgress by animateFloatAsState(trackProgress.toFloat())
    var muted by remember { mutableStateOf(true) }
    var playing by remember { mutableStateOf<Track?>(null) }
    val playerListState = rememberLazyListState()
    val selectedPlayerItem = rememberSnapFlingSelectedIndex(playerListState)

    var width by remember { mutableStateOf(0.dp) }
    val density = LocalDensity.current
    val isDarkMode = isSystemInDarkTheme()

    LaunchedEffect(true) {
        artist.theme?.toColorScheme()?.let {
            onColorsChanged(it)
        }
    }

    LaunchedEffect(playing) {
        playing?.let {
            val index = artist.topTracks?.indexOfFirst { playing?.previewUrl == it.previewUrl } ?: 0
            val isLast = index == (artist.topTracks?.size?.minus(1) ?: 0)
            val offset = if (index == 0) 0 else with(density) { -(width / 6).toPx().toInt() }
            if (selectedPlayerItem.value != index) {
                playerListState.animateScrollToItem(index, offset)
            }
            playing?.previewUrl?.let { previewUrl ->
                audioPlayer.playAudio(previewUrl, muted) { progress ->
                    trackProgress = progress
                    if (trackProgress == 1.0) {
                        val next = if (isLast) 0 else index + 1
                        artist.topTracks?.getOrNull(next)?.let {
                            trackProgress = 0.0
                            playing = it
                        }
                    }
                }
            }
        }
    }

    LaunchedEffect(muted) {
        audioPlayer.mute(muted)
    }

//    LaunchedEffect(playerListState.firstVisibleItemIndex, playerListState.canScrollForward, playerListState.isScrollInProgress) {
//        if (!playerListState.isScrollInProgress) {
//            artist.topTracks?.getOrNull(selectedPlayerItem.value)?.let { selectedTrack ->
//                if (playing != selectedTrack) {
//                    trackProgress = 0.0
//                    playing = selectedTrack
//                    muted = false
//                }
//            }
//        }
//    }

    DisposableEffect(true) {
        this.onDispose {
            audioPlayer.stopAudio()
        }
    }

    fun openUrl(url: String, host: String) {
        uriHandler.openUri(
            try {
                if (Url(url).host.removePrefix("www.") == host) {
                    url
                } else {
                    "https://$host/${url}"
                }
            } catch (e: Exception) {
                "https://$host/${url}"
            }
        )
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
    ) {
        LazyColumn(
            Modifier
                .fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally,
            state = listState
        ) {
            item {
                Column(
                    modifier = Modifier
                        .fillMaxHeight()
                        .padding()
                ) {
                    Row {
                        Column {

                            ArtistHeader(artist)

                            Row(
                                modifier = Modifier.padding(
                                    horizontal = 12.dp
                                )
                                    .padding(bottom = 12.dp)
                            ) {
                                artist.facebook?.takeIf { it.isNotEmpty() && it != "null" }
                                    ?.let { facebook ->
                                        OutlinedButton(
                                            modifier = Modifier
                                                .weight(1f, true)
                                                .height(48.dp),
                                            onClick = {
                                                openUrl(facebook, "facebook.com")
                                            },
                                            shape = RoundedCornerShape(12.dp),
                                            colors = ButtonDefaults.outlinedButtonColors(
                                                containerColor = MaterialTheme.colorScheme.surfaceContainer,
                                            ),
                                            border = BorderStroke(1.dp, Color.Transparent)
                                        ) {
                                            Icon(
                                                modifier = Modifier
                                                    .size(24.dp)
                                                    .align(Alignment.CenterVertically)
                                                    .padding(end = 4.dp),
                                                imageVector = vectorResource(Res.drawable.facebook),
                                                contentDescription = "Facebook",
                                            )
                                        }
                                    }

                                artist?.instagram?.takeIf { it.isNotEmpty() && it != "null" }
                                    ?.let { instagram ->
                                        OutlinedButton(
                                            modifier = Modifier.weight(1f, true)
                                                .padding(horizontal = 4.dp)
                                                .height(48.dp),
                                            onClick = {
                                                openUrl(instagram, "instagram.com")
                                            },
                                            shape = RoundedCornerShape(12.dp),
                                            colors = ButtonDefaults.outlinedButtonColors(
                                                containerColor = MaterialTheme.colorScheme.surfaceContainer,
                                            ),
                                            border = BorderStroke(1.dp, Color.Transparent)
                                        ) {
                                            Icon(
                                                modifier = Modifier
                                                    .size(24.dp)
                                                    .align(Alignment.CenterVertically)
                                                    .padding(end = 4.dp),
                                                imageVector = vectorResource(Res.drawable.instagram),
                                                contentDescription = "Instagram",
                                            )
                                        }
                                    }

                                artist?.tiktok?.takeIf { it.isNotEmpty() && it != "null" }
                                    ?.let { tiktok ->
                                        OutlinedButton(
                                            modifier = Modifier
                                                .weight(1f, true)
                                                .padding(horizontal = 4.dp)
                                                .height(48.dp),
                                            onClick = {
                                                openUrl(tiktok, "tiktok.com")
                                            },
                                            shape = RoundedCornerShape(12.dp),
                                            colors = ButtonDefaults.outlinedButtonColors(
                                                containerColor = MaterialTheme.colorScheme.surfaceContainer,
                                            ),
                                            border = BorderStroke(1.dp, Color.Transparent)
                                        ) {
                                            Icon(
                                                modifier = Modifier
                                                    .size(24.dp)
                                                    .align(Alignment.CenterVertically)
                                                    .padding(end = 4.dp),
                                                imageVector = vectorResource(Res.drawable.tiktok),
                                                contentDescription = "Instagram"
                                            )
                                        }
                                    }

                                artist?.email?.takeIf { it.isNotEmpty() && it != "null" }
                                    ?.let { email ->
                                        OutlinedButton(
                                            modifier = Modifier.weight(1f, true)
                                                .height(48.dp),
                                            onClick = {
                                                uriHandler.openUri("mailto:${email}")
                                            },
                                            shape = RoundedCornerShape(12.dp),
                                            colors = ButtonDefaults.outlinedButtonColors(
                                                containerColor = MaterialTheme.colorScheme.surfaceContainer,
                                            ),
                                            border = BorderStroke(1.dp, Color.Transparent)
                                        ) {
                                            Icon(
                                                modifier = Modifier
                                                    .size(32.dp)
                                                    .align(Alignment.CenterVertically),
                                                imageVector = vectorResource(Res.drawable.mail),
                                                contentDescription = "Email"
                                            )
                                        }
                                    }
                            }

                            Column(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .padding(horizontal = 12.dp)
                                    .clip(RoundedCornerShape(24.dp))
                                    .background(MaterialTheme.colorScheme.surfaceContainer)
                                    .padding(top = 12.dp)
                            ) {
                                LazyRow(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .onSizeChanged {
                                            width = with(density) { it.width.toDp() * 0.7f }
                                        },
                                    state = playerListState,
                                    flingBehavior = rememberSnapFlingBehavior(playerListState)
                                ) {

                                    itemsIndexed(artist.topTracks ?: listOf()) { i, track ->
                                        Spacer(Modifier.width(12.dp))

                                        val isPlaying = playing?.previewUrl == track.previewUrl
                                        Box(
                                            Modifier
                                                .width(width)
                                                .height(92.dp)
                                                .clip(RoundedCornerShape(12.dp))
                                                .clickable {
                                                    if (isPlaying) {
                                                        muted = !muted
                                                    } else {
                                                        playing = track
                                                        muted = false
                                                    }
                                                }
                                        ) {
                                            AsyncImage(
                                                modifier = Modifier
                                                    .fillMaxWidth()
                                                    .blur(12.dp)
                                                    .alpha(0.3f),
                                                model = track.album?.images?.firstOrNull()?.url,
                                                contentDescription = track.name,
                                                contentScale = ContentScale.FillWidth
                                            )
                                            Row(
                                                Modifier
                                                    .fillMaxWidth()
                                                    .align(Alignment.Center)
                                            ) {
                                                Box(
                                                    modifier = Modifier
                                                        .size(92.dp)
                                                        .padding(12.dp)
                                                        .align(Alignment.CenterVertically)
                                                        .clip(RoundedCornerShape(12.dp))
                                                ) {
                                                    AsyncImage(
                                                        modifier = Modifier,
                                                        model = track.album?.images?.firstOrNull()?.url,
                                                        contentDescription = track.name,
                                                        contentScale = ContentScale.Crop
                                                    )
                                                }

                                                Column(
                                                    Modifier
                                                        .align(Alignment.CenterVertically)
                                                        .padding(start = 4.dp)
                                                        .weight(1f, true)
                                                ) {
                                                    androidx.compose.animation.AnimatedVisibility (isPlaying) {
                                                        Row {
                                                            Text(
                                                                modifier = Modifier
                                                                    .align(Alignment.CenterVertically),
                                                                text = "Playing",
                                                                fontWeight = FontWeight.Medium,
                                                                fontSize = 12.sp,
                                                                color = MaterialTheme.colorScheme.primary
                                                            )

                                                            Spacer(Modifier.width(8.dp))

                                                            PlayingAnimation(
                                                                Modifier
                                                                    .align(Alignment.CenterVertically)
                                                                    .padding(top = 4.dp)
                                                            )
                                                        }
                                                    }
                                                    Text(
                                                        modifier = Modifier,
                                                        text = track.name,
                                                        fontWeight = FontWeight.Medium,
                                                        maxLines = 1,
                                                        overflow = TextOverflow.Ellipsis,
                                                    )
                                                }

                                                AnimatedVisibility(
                                                    visible = isPlaying && muted,
                                                    modifier = Modifier
                                                        .align(Alignment.CenterVertically)
                                                        .padding(horizontal = 12.dp)
                                                ) {
                                                    IconButton(
                                                        modifier = Modifier
                                                            .clip(CircleShape)
                                                            .background(
                                                                MaterialTheme.colorScheme.surfaceContainer.copy(
                                                                    alpha = 0.5f
                                                                )
                                                            ),
                                                        onClick = {
                                                            muted = false
                                                        },
                                                    ) {
                                                        Icon(
                                                            imageVector = vectorResource(Res.drawable.muted),
                                                            contentDescription = "Unmute"
                                                        )
                                                    }
                                                }
                                            }

                                            androidx.compose.animation.AnimatedVisibility (
                                                visible = isPlaying,
                                                modifier = Modifier.align(Alignment.BottomCenter)
                                            ) {
                                                LinearProgressIndicator(
                                                    modifier = Modifier
                                                        .fillMaxWidth(),
                                                    progress = trackProgress.toFloat()
                                                )
                                            }
                                        }
                                    }

                                    item {
                                        Spacer(Modifier.width(12.dp))
                                    }
                                }

                                Row(
                                    Modifier.padding(12.dp)
                                ) {
                                    Box(
                                        modifier = Modifier
                                            .weight(1f, true)
                                            .clip(RoundedCornerShape(12.dp))
                                            .background(Color(0xFF1DB954).copy(alpha = 0.9f))
                                            .clickable {
                                                uriHandler.openUri("https://open.spotify.com/artist/${artist.spotify}")
                                            }
                                            .padding(12.dp)
                                    ) {
                                        Row(
                                            Modifier
                                                .align(Alignment.Center)
                                        ) {
                                            Icon(
                                                imageVector = vectorResource(Res.drawable.spotify),
                                                contentDescription = "Spotify",
                                                tint = Color.White
                                            )
                                            Spacer(Modifier.width(8.dp))
                                            Text(
                                                modifier = Modifier.align(Alignment.CenterVertically),
                                                text = "Spotify",
                                                fontWeight = FontWeight.SemiBold,
                                                fontSize = 16.sp,
                                                color = Color.White
                                            )
                                        }
                                    }

                                    artist.appleMusic?.let { appleMusic ->
                                        Spacer(Modifier.width(12.dp))
                                        Box(
                                            modifier = Modifier
                                                .weight(1f, true)
                                                .clip(RoundedCornerShape(12.dp))
                                                .background(Color(0xFFFA2D48).copy(alpha = 0.9f))
                                                .clickable {
                                                    uriHandler.openUri(appleMusic)
                                                }
                                                .padding(12.dp)
                                        ) {
                                            Row(
                                                Modifier
                                                    .align(Alignment.Center)
                                            ) {
                                                Icon(
                                                    modifier = Modifier.height(24.dp),
                                                    imageVector = vectorResource(Res.drawable.apple_logo_black),
                                                    contentDescription = "Apple",
                                                    tint = Color.White
                                                )
                                                Spacer(Modifier.width(8.dp))
                                                Text(
                                                    modifier = Modifier.align(Alignment.CenterVertically),
                                                    text = "Music",
                                                    fontWeight = FontWeight.SemiBold,
                                                    fontSize = 16.sp,
                                                    color = Color.White
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            item {
                artist.soundsLike?.let { soundsLike ->
                    Spacer(modifier = Modifier.height(12.dp))

                    Column(
                        modifier = Modifier
                            .align(Alignment.TopStart)
                            .fillMaxWidth()
                            .padding(horizontal = 24.dp)
                            .padding(bottom = 24.dp, top = 12.dp)
                    ) {
                        Text(
                            modifier = Modifier
                                .padding(bottom = 8.dp),
                            text = "Sounds Like",
                            fontWeight = FontWeight.SemiBold,
                            fontSize = 20.sp,
                        )
                        Text(
                            modifier = Modifier,
                            text = soundsLike.map { it.name }.joinToString(", "),
                            fontSize = 14.sp
                        )
                    }
                }
            }


            itemsIndexed(shows) { i, show ->
                if (i == 0) {
                    Spacer(modifier = Modifier.height(12.dp))
                }
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(horizontal = 12.dp)
                        .clip(RoundedCornerShape(24.dp))
                        .background(MaterialTheme.colorScheme.primary)
                        .clickable {
                            uriHandler.openUri(show.link)
                        }
                ) {
//                        Image(
//                            modifier = Modifier.fillMaxWidth(),
//                            painter = painterResource(Res.drawable.fastlane),
//                            contentDescription = null,
//                            contentScale = ContentScale.FillWidth
//                        )
                }
                if (i != shows.size - 1) {
                    Spacer(modifier = Modifier.height(12.dp))
                }
            }

            item {
                artist?.bio?.takeIf { it.isNotEmpty() && it != "null" }?.let { bio ->
                    Column(
                        modifier = Modifier
                            .align(Alignment.TopStart)
                            .padding(horizontal = 24.dp)
                            .fillMaxWidth()
                    ) {
                        Text(
                            modifier = Modifier
                                .padding(bottom = 8.dp),
                            text = "About",
                            fontWeight = FontWeight.SemiBold,
                            fontSize = 20.sp,
                        )
                        Text(
                            modifier = Modifier,
                            text = bio,
                            fontSize = 14.sp
                        )
                    }
                }
            }

            item {
                Spacer(
                    modifier = if
                                       (ScreenInfo().isPortrait())
                        Modifier.navigationBarsPadding()
                            .padding(bottom = if (spotifyPlayerState.value != null) 84.dp else 0.dp)
                    else Modifier.padding(bottom = if (spotifyPlayerState.value != null) 84.dp else 12.dp)
                )
            }
        }
    }
}

@Composable
fun rememberSnapFlingSelectedIndex(listState: LazyListState) = remember {
    derivedStateOf {
        with(listState) {
            val itemsOnScreen = layoutInfo.visibleItemsInfo.size.takeIf { it != 0 } ?: 1
            val totalItems = layoutInfo.totalItemsCount
            when {
                !canScrollBackward -> firstVisibleItemIndex
                !canScrollForward -> totalItems - 1
                else -> firstVisibleItemIndex + itemsOnScreen.div(2)
                // Or you can write your own conditions to determine item position
                // ...
            }
        }
    }

}