package data


import Log
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.FirebaseAnalyticsEvents
import dev.gitlive.firebase.analytics.analytics

class Analytics {

    private val analytics = Firebase.analytics

    fun log(event: Event) {
        if (isDebug) {
            Log().i("Analytics","${event.name} / ${event.params}")
        } else {
            analytics.logEvent(event.name, event.params)
        }
    }

}

expect val isDebug: Boolean

sealed class Event(
    val name: String,
    val params: Map<String, String>
) {
    class PageView(
        params: Map<String, String> = mapOf()
    ): Event(FirebaseAnalyticsEvents.SCREEN_VIEW, params)
}