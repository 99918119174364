@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package loca.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val apple_logo_black: DrawableResource by 
      lazy { init_apple_logo_black() }

  public val arrow_left: DrawableResource by 
      lazy { init_arrow_left() }

  public val arrow_left_1: DrawableResource by 
      lazy { init_arrow_left_1() }

  public val arrow_right: DrawableResource by 
      lazy { init_arrow_right() }

  public val check: DrawableResource by 
      lazy { init_check() }

  public val close: DrawableResource by 
      lazy { init_close() }

  public val copy: DrawableResource by 
      lazy { init_copy() }

  public val dragger: DrawableResource by 
      lazy { init_dragger() }

  public val edit: DrawableResource by 
      lazy { init_edit() }

  public val exit: DrawableResource by 
      lazy { init_exit() }

  public val eye: DrawableResource by 
      lazy { init_eye() }

  public val eye_off: DrawableResource by 
      lazy { init_eye_off() }

  public val facebook: DrawableResource by 
      lazy { init_facebook() }

  public val google: DrawableResource by 
      lazy { init_google() }

  public val icon: DrawableResource by 
      lazy { init_icon() }

  public val instagram: DrawableResource by 
      lazy { init_instagram() }

  public val location: DrawableResource by 
      lazy { init_location() }

  public val logo: DrawableResource by 
      lazy { init_logo() }

  public val mail: DrawableResource by 
      lazy { init_mail() }

  public val music: DrawableResource by 
      lazy { init_music() }

  public val muted: DrawableResource by 
      lazy { init_muted() }

  public val pause: DrawableResource by 
      lazy { init_pause() }

  public val play: DrawableResource by 
      lazy { init_play() }

  public val powered_by_google: DrawableResource by 
      lazy { init_powered_by_google() }

  public val qr_code: DrawableResource by 
      lazy { init_qr_code() }

  public val search: DrawableResource by 
      lazy { init_search() }

  public val share: DrawableResource by 
      lazy { init_share() }

  public val slider: DrawableResource by 
      lazy { init_slider() }

  public val spotify: DrawableResource by 
      lazy { init_spotify() }

  public val tiktok: DrawableResource by 
      lazy { init_tiktok() }
}

internal val Res.drawable.apple_logo_black: DrawableResource
  get() = CommonMainDrawable0.apple_logo_black

private fun init_apple_logo_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:apple_logo_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/apple_logo_black.xml", -1, -1),
    )
)

internal val Res.drawable.arrow_left: DrawableResource
  get() = CommonMainDrawable0.arrow_left

private fun init_arrow_left(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:arrow_left",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/arrow_left.xml", -1, -1),
    )
)

internal val Res.drawable.arrow_left_1: DrawableResource
  get() = CommonMainDrawable0.arrow_left_1

private fun init_arrow_left_1(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:arrow_left_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/arrow_left_1.xml", -1, -1),
    )
)

internal val Res.drawable.arrow_right: DrawableResource
  get() = CommonMainDrawable0.arrow_right

private fun init_arrow_right(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:arrow_right",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/arrow_right.xml", -1, -1),
    )
)

internal val Res.drawable.check: DrawableResource
  get() = CommonMainDrawable0.check

private fun init_check(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:check",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/check.xml", -1, -1),
    )
)

internal val Res.drawable.close: DrawableResource
  get() = CommonMainDrawable0.close

private fun init_close(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:close",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/close.xml", -1, -1),
    )
)

internal val Res.drawable.copy: DrawableResource
  get() = CommonMainDrawable0.copy

private fun init_copy(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:copy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/copy.xml", -1, -1),
    )
)

internal val Res.drawable.dragger: DrawableResource
  get() = CommonMainDrawable0.dragger

private fun init_dragger(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:dragger",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/dragger.xml", -1, -1),
    )
)

internal val Res.drawable.edit: DrawableResource
  get() = CommonMainDrawable0.edit

private fun init_edit(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:edit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/edit.xml", -1, -1),
    )
)

internal val Res.drawable.exit: DrawableResource
  get() = CommonMainDrawable0.exit

private fun init_exit(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:exit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/exit.xml", -1, -1),
    )
)

internal val Res.drawable.eye: DrawableResource
  get() = CommonMainDrawable0.eye

private fun init_eye(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:eye",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/eye.xml", -1, -1),
    )
)

internal val Res.drawable.eye_off: DrawableResource
  get() = CommonMainDrawable0.eye_off

private fun init_eye_off(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:eye_off",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/eye_off.xml", -1, -1),
    )
)

internal val Res.drawable.facebook: DrawableResource
  get() = CommonMainDrawable0.facebook

private fun init_facebook(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:facebook",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/facebook.xml", -1, -1),
    )
)

internal val Res.drawable.google: DrawableResource
  get() = CommonMainDrawable0.google

private fun init_google(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:google",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/google.xml", -1, -1),
    )
)

internal val Res.drawable.icon: DrawableResource
  get() = CommonMainDrawable0.icon

private fun init_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/icon.xml", -1, -1),
    )
)

internal val Res.drawable.instagram: DrawableResource
  get() = CommonMainDrawable0.instagram

private fun init_instagram(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:instagram",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/instagram.xml", -1, -1),
    )
)

internal val Res.drawable.location: DrawableResource
  get() = CommonMainDrawable0.location

private fun init_location(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:location",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/location.xml", -1, -1),
    )
)

internal val Res.drawable.logo: DrawableResource
  get() = CommonMainDrawable0.logo

private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/logo.xml", -1, -1),
    )
)

internal val Res.drawable.mail: DrawableResource
  get() = CommonMainDrawable0.mail

private fun init_mail(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:mail",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/mail.xml", -1, -1),
    )
)

internal val Res.drawable.music: DrawableResource
  get() = CommonMainDrawable0.music

private fun init_music(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:music",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/music.xml", -1, -1),
    )
)

internal val Res.drawable.muted: DrawableResource
  get() = CommonMainDrawable0.muted

private fun init_muted(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:muted",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/muted.xml", -1, -1),
    )
)

internal val Res.drawable.pause: DrawableResource
  get() = CommonMainDrawable0.pause

private fun init_pause(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:pause",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/pause.xml", -1, -1),
    )
)

internal val Res.drawable.play: DrawableResource
  get() = CommonMainDrawable0.play

private fun init_play(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:play",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/play.xml", -1, -1),
    )
)

internal val Res.drawable.powered_by_google: DrawableResource
  get() = CommonMainDrawable0.powered_by_google

private fun init_powered_by_google(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:powered_by_google",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/powered_by_google.png", -1, -1),
    )
)

internal val Res.drawable.qr_code: DrawableResource
  get() = CommonMainDrawable0.qr_code

private fun init_qr_code(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:qr_code",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/qr_code.xml", -1, -1),
    )
)

internal val Res.drawable.search: DrawableResource
  get() = CommonMainDrawable0.search

private fun init_search(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:search",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/search.xml", -1, -1),
    )
)

internal val Res.drawable.share: DrawableResource
  get() = CommonMainDrawable0.share

private fun init_share(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:share",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/share.xml", -1, -1),
    )
)

internal val Res.drawable.slider: DrawableResource
  get() = CommonMainDrawable0.slider

private fun init_slider(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:slider",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/slider.xml", -1, -1),
    )
)

internal val Res.drawable.spotify: DrawableResource
  get() = CommonMainDrawable0.spotify

private fun init_spotify(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:spotify",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/spotify.xml", -1, -1),
    )
)

internal val Res.drawable.tiktok: DrawableResource
  get() = CommonMainDrawable0.tiktok

private fun init_tiktok(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:tiktok",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/drawable/tiktok.xml", -1, -1),
    )
)
