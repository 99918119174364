package data

import Log
import SpotifyArtist
import TopTracksResponse
import Track
import io.ktor.client.call.body
import io.ktor.client.request.bearerAuth
import io.ktor.client.request.parameter
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.HttpMethod
import io.ktor.http.contentType
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject

class SpotifyRepository(private val locaHttp: LocaHttp) {

    private val log = Log()

    private suspend fun token(): String? {
        val tokenRequest = locaHttp.request("https://accounts.spotify.com/api/token") {
            method = HttpMethod.Post
            contentType(ContentType.Application.FormUrlEncoded)
            parameter("grant_type", "client_credentials")
            parameter("client_id", "ff892d728dd1425a93fd0d96f194d12a")
            parameter("client_secret", "b0399a9fcbe840dab2d6f659f212910a")
        }

        log.i("ktorToken", tokenRequest.bodyAsText())
        return tokenRequest.body<JsonObject>()["access_token"]?.toString()?.removeSurrounding("\"")
    }

    suspend fun getTopTracks(id: String): List<Track> {
        val token = token()
        val response =
            locaHttp.request("https://api.spotify.com/v1/artists/${id}/top-tracks") {
                bearerAuth(token ?: "")
            }
        log.i("ktorTracks", response.bodyAsText())

        return response.body<TopTracksResponse>().tracks
    }

    suspend fun getArtistDetails(id: String): SpotifyArtist {
        val token = token()
        val response =
            locaHttp.request("https://api.spotify.com/v1/artists/${id}") {
                bearerAuth(token ?: "")
            }
        log.i("ktorTracks", response.bodyAsText())

        return response.body<SpotifyArtist>()
    }

    @Serializable
    data class ArtistSearchResponse(
        val artists: ArtistSearchArtists
    )

    @Serializable
    data class ArtistSearchArtists(
        val items: List<SpotifyArtist>
    )
    suspend fun searchArtists(query: String): List<SpotifyArtist> {
        val token = token()
        val response =
            locaHttp.request("https://api.spotify.com/v1/search") {
                bearerAuth(token ?: "")
                parameter("query", query)
                parameter("type", "artist")
            }
        log.i("ktorTracks", response.bodyAsText())

        return response.body<ArtistSearchResponse>().artists.items
    }

}