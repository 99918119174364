import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Track(
    val id: String,
    val name: String,
    @SerialName("preview_url") val previewUrl: String? = null,
    val uri: String,
    val artistName: String? = null,
    val artistUri: String? = null,
    val album: SpotifyAlbum? = null,
    @SerialName("duration_ms") val durationMs: String? = null,
    val explicit: Boolean? = null,
)

@Serializable
data class TopTracksResponse(
    val tracks: List<Track>
)

@Serializable
data class SpotifyImage(
    val url: String,
    val height: Int,
    val width: Int,
)

@Serializable
data class SpotifyArtist(
    val id: String,
    val name: String,
    val images: List<SpotifyImage>,
    val genres: List<String>? = null,
    val followers: Followers? = null,
)

@Serializable
data class Followers(
    val total: Int? = null,
)

@Serializable
data class SpotifyAlbum(
    @SerialName("album_type") val albumType: String,
    val images: List<SpotifyImage>,
    @SerialName("release_date") val releaseDate: String,
)