package ui.create

import Artist
import ScreenInfo
import SpotifyArtist
import Track
import androidx.compose.animation.slideInVertically
import androidx.compose.animation.slideOutVertically
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.runtime.toMutableStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.compose.currentBackStackEntryAsState
import androidx.navigation.compose.rememberNavController
import data.LocalFirestore
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.arrow_left
import loca.composeapp.generated.resources.edit
import org.jetbrains.compose.resources.vectorResource

@Composable
fun ArtistOnboarding(
    selectedArtist: Artist?,
    onClose: () -> Unit,
    onSave: (Artist) -> Unit,
) {

    var editedArtist by remember { mutableStateOf(selectedArtist) }
    var spotifyArtist by remember { mutableStateOf<SpotifyArtist?>(null) }
    var topTracks by remember { mutableStateOf<List<Track>?>(null) }

    val isPortrait = ScreenInfo().isPortrait()
    val navController = rememberNavController()
    val currentBackStackEntry by navController.currentBackStackEntryAsState()

    val firestore = LocalFirestore.current
    val coroutineScope = rememberCoroutineScope { Dispatchers.Unconfined }

    Row(
        modifier = Modifier.fillMaxSize()
    ) {

        Column(
            modifier = Modifier
                .fillMaxHeight()
                .weight(1f, true)
        ) {
            Row(
                modifier = if (isPortrait) Modifier
                    .fillMaxWidth()
                    .background(MaterialTheme.colorScheme.surfaceContainer.copy(0.8f))
                    .padding(
                        vertical = 12.dp
                    )
                else Modifier
                    .fillMaxWidth()
                    .padding(top = 24.dp)
                    .clip(CircleShape)
                    .background(MaterialTheme.colorScheme.surfaceContainer.copy(0.8f))
                    .padding(
                        vertical = 12.dp
                    )
                    .padding(end = 24.dp)
            ) {
                when (currentBackStackEntry?.destination?.route) {
                    "/preview" -> {
                        Text(
                            modifier = Modifier.align(Alignment.CenterVertically),
                            text = "Preview",
                            fontWeight = FontWeight.SemiBold,
                            fontSize = 18.sp
                        )

                        Spacer(Modifier.weight(1f, true))

                        Button(
                            onClick = {
                                navController.popBackStack()
                            },
                            shape = CircleShape
                        ) {
                            Icon(
                                imageVector = vectorResource(Res.drawable.edit),
                                contentDescription = "Edit"
                            )

                            Spacer(Modifier.width(8.dp))

                            Text("Edit")
                        }
                    }

                    else -> {
                        IconButton(
                            modifier = Modifier.padding(start = 8.dp, end = 4.dp),
                            onClick = {
                                if (navController.previousBackStackEntry == null) {
                                    onClose()
                                } else {
                                    navController.popBackStack()
                                }
                            },
                        ) {
                            Icon(
                                imageVector = vectorResource(Res.drawable.arrow_left),
                                contentDescription = "Back"
                            )
                        }

                        Text(
                            modifier = Modifier.align(Alignment.CenterVertically),
                            text = "Create Artist",
                            fontWeight = FontWeight.SemiBold,
                            fontSize = 18.sp
                        )

                        Spacer(Modifier.weight(1f, true))

//                        Button(
//                            modifier = Modifier,
//                            onClick = {
//                                if (isPortrait) {
//                                    navController.navigate("/preview")
//                                } else {
//                                    coroutineScope.launch {
//                                        firestore.updateArtist(editedArtist)
//                                        onSave(editedArtist)
//                                    }
//                                }
//                            },
//                            shape = CircleShape,
//                        ) {
//                            if (isPortrait) {
//                                Icon(
//                                    imageVector = vectorResource(Res.drawable.eye),
//                                    contentDescription = "Preview"
//                                )
//
//                                Spacer(Modifier.width(8.dp))
//
//                                Text("Preview")
//                            } else {
////                                Icon(
////                                    imageVector = vectorResource(Res.drawable.),
////                                    contentDescription = "Preview"
////                                )
//
////                                Spacer(Modifier.width(8.dp))
//
//                                Text("Save")
//                            }
//                        }
                    }
                }
            }

            val start = if (selectedArtist == null) {
                "/handle"
            } else {
                when {
                    selectedArtist.spotify == null -> {
                        "/spotify"
                    }

                    else -> {
                        "/handle".apply {
                            onClose()
                        }
                    }
                }
            }

            NavHost(
                modifier = Modifier.fillMaxSize().padding(horizontal = 24.dp),
                navController = navController,
                startDestination = start,
                enterTransition = {
                    slideInVertically { it }
                },
                exitTransition = {
                    slideOutVertically { -it }
                },
                popEnterTransition = {
                    slideInVertically { -it }
                },
                popExitTransition = {
                    slideOutVertically { it }
                },
            ) {

                composable("/handle") {
                    SelectHandle { createdArtist ->
                        editedArtist = createdArtist
                        navController.navigate("/spotify")
                    }
                }

                composable("/spotify") {
                    if (editedArtist == null) {
                        onClose()
                    }

                    editedArtist?.let { artist ->
                        FindBand { result, tracks ->
                            spotifyArtist = result
                            topTracks = tracks
                            navController.navigate("/confirm")
                        }
                    }
                }

                composable("/confirm") {
                    spotifyArtist?.let { result ->
                        ConfirmBand(
                            spotifyArtist = result,
                            topTracks = topTracks ?: listOf(),
                            onYes = {
                                coroutineScope.launch {
                                    val updated = editedArtist!!.copy(
                                        spotify = result.id,
                                        name = result.name,
                                        picture = result.images.firstOrNull()?.url,
                                        topTracks = topTracks,
                                        genres = result.genres,
                                        images = result.images,
                                    )
                                    firestore.updateArtist(updated)
                                    editedArtist = updated
                                    navController.navigate("/location")
                                }
                            },
                            onNo = {
                                navController.popBackStack()
                                spotifyArtist = null
                                topTracks = null
                            }
                        )
                    }
                }

                composable("/location") {
                    LocationSearch { location ->
                        coroutineScope.launch {
                            val updated = editedArtist!!.copy(
                                location = location
                            )
                            firestore.updateArtist(updated)
                            editedArtist = updated
                            navController.navigate("/soundslike")
                        }
                    }
                }

                composable("/soundslike") {
                    SoundLike(editedArtist?.soundsLike?: listOf()) { soundsLike ->
                        coroutineScope.launch {
                            if (soundsLike.isNotEmpty()) {
                                val updated = editedArtist!!.copy(
                                    soundsLike = soundsLike
                                )
                                firestore.updateArtist(updated)
                                editedArtist = updated
                            }
                            navController.navigate("/genres")
                        }
                    }
                }

                composable("/genres") {
                    val genres = editedArtist?.genres?.toMutableStateList() ?: listOf<String>().toMutableStateList()
                    PickGenres(genres, editedArtist!!.soundsLike ?: listOf()) {
                        coroutineScope.launch {
                            val updated = editedArtist!!.copy(
                                genres = genres
                            )
                            firestore.updateArtist(updated)
                            editedArtist = updated
                            withContext(Dispatchers.Main) {
                                onSave(updated)
                            }
                        }
                    }
                }
            }
        }
    }
}