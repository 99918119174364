package ui.component

import androidx.compose.animation.core.VisibilityThreshold
import androidx.compose.animation.core.animateIntAsState
import androidx.compose.animation.core.spring
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.unit.dp

@Composable
fun PlayingAnimation(modifier: Modifier = Modifier) {

    var animating by remember { mutableStateOf(false) }

    val heightList = remember { mutableStateListOf(3, 12, 6) }

    val spec = spring(
        visibilityThreshold = Int.VisibilityThreshold
    )
    val height1 by animateIntAsState(
        animationSpec = spec,
        targetValue = heightList[0],
        finishedListener = {
            animating = false
        }
    )
    val height2 by animateIntAsState(heightList[1], animationSpec = spec)
    val height3 by animateIntAsState(heightList[2], animationSpec = spec)

    LaunchedEffect(animating) {
        if (!animating) {
            val h0 = heightList[0]
            val h1 = heightList[1]
            val h2 = heightList[2]
            heightList[0] = h2
            heightList[1] = h0
            heightList[2] = h1
            animating = true
        }
    }

    Row(
        modifier = modifier
            .height(12.dp)
    ) {
        Box(
            modifier = Modifier
                .align(Alignment.CenterVertically)
                .width(2.dp)
                .height(height1.dp)
                .clip(CircleShape)
                .background(MaterialTheme.colorScheme.primary)
        )

        Spacer(Modifier.width(2.dp))

        Box(
            modifier = Modifier
                .align(Alignment.CenterVertically)
                .width(2.dp)
                .height(height2.dp)
                .clip(CircleShape)
                .background(MaterialTheme.colorScheme.primary)
        )

        Spacer(Modifier.width(2.dp))

        Box(
            modifier = Modifier
                .align(Alignment.CenterVertically)
                .width(2.dp)
                .height(height3.dp)
                .clip(CircleShape)
                .background(MaterialTheme.colorScheme.primary)
        )
    }
}