package util

import androidx.compose.ui.graphics.ImageBitmap
import androidx.compose.ui.graphics.asComposeImageBitmap
import org.jetbrains.skia.Bitmap

actual class ImageUtil actual constructor() {
    actual fun imageBitmapFromBytes(bytes: ByteArray): ImageBitmap? {
        return null
    }

    actual fun imageBitmapFromBitmap(bitmap: Bitmap): ImageBitmap? {
        return bitmap.asComposeImageBitmap()
    }
}