@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package loca.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.FontResource

private object CommonMainFont0 {
  public val Inter_Bold: FontResource by 
      lazy { init_Inter_Bold() }

  public val Inter_Medium: FontResource by 
      lazy { init_Inter_Medium() }

  public val Inter_Regular: FontResource by 
      lazy { init_Inter_Regular() }

  public val Inter_SemiBold: FontResource by 
      lazy { init_Inter_SemiBold() }
}

internal val Res.font.Inter_Bold: FontResource
  get() = CommonMainFont0.Inter_Bold

private fun init_Inter_Bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Inter_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/font/Inter-Bold.ttf", -1, -1),
    )
)

internal val Res.font.Inter_Medium: FontResource
  get() = CommonMainFont0.Inter_Medium

private fun init_Inter_Medium(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Inter_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/font/Inter-Medium.ttf", -1, -1),
    )
)

internal val Res.font.Inter_Regular: FontResource
  get() = CommonMainFont0.Inter_Regular

private fun init_Inter_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Inter_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/font/Inter-Regular.ttf", -1, -1),
    )
)

internal val Res.font.Inter_SemiBold: FontResource
  get() = CommonMainFont0.Inter_SemiBold

private fun init_Inter_SemiBold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:Inter_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/loca.composeapp.generated.resources/font/Inter-SemiBold.ttf", -1, -1),
    )
)
