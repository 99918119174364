package data

import Artist
import dev.gitlive.firebase.firestore.FieldPath
import dev.gitlive.firebase.firestore.FirebaseFirestore
import dev.gitlive.firebase.firestore.Source
import util.GeoBoundingBox

class LocaFirestore(private val firestore: FirebaseFirestore) {

    suspend fun getArtistFromCache(id: String) =
        firestore
            .collection("artists")
            .document(id)
            .get(Source.CACHE)
            .data(Artist.serializer()).copy(id = id)

    suspend fun getArtistsForLocation(boundingBox: GeoBoundingBox) =
        firestore
            .collection("artists")
            .where {
                val locationPath = FieldPath("location", "point")
                all(
                    locationPath greaterThan boundingBox.swCorner,
                    locationPath lessThan boundingBox.neCorner
                )
            }
            .get()
            .documents.map {
                it.data(Artist.serializer()).copy(id = it.id)
            }

    suspend fun getArtistsByName(name: String) =
        firestore
            .collection("artists")
            .where {
                all(
                    "name" greaterThanOrEqualTo name,
                    "name" lessThanOrEqualTo "$name\uf8ff"
                )
            }
            .get()
            .documents.map {
                it.data(Artist.serializer()).copy(id = it.id)
            }

    suspend fun getArtistByUsername(username: String) =
        firestore
            .collection("artists")
            .where {
                all(
                    "handle" equalTo username,
                )
            }
            .limit(1)
            .get()
            .documents.firstOrNull()?.let {
                it.data(Artist.serializer()).copy(id = it.id)
            }

    suspend fun getArtistsForUser(email: String) =
        firestore
            .collection("artists")
            .where {
                all(
                    "editors" contains email,
                )
            }
            .get()
            .documents.map {
                it.data(Artist.serializer()).copy(id = it.id)
            }

    suspend fun createArtist(
        handle: String,
        creator: String,
    ) =
        firestore.runTransaction {
            firestore
                .collection("artists")
                .add(mapOf(
                    "handle" to handle,
                    "editors" to listOf(creator),
                    "createdBy" to creator,
                ))
                .let {
                    Artist(
                        id = it.id,
                        handle = handle,
                        editors = listOf(creator),
                        createdBy = creator
                    ).also { artist ->
                        firestore.collection("handles").document(handle).let {
                            this.set(it, mapOf("artist" to artist.id))
                        }
                    }
                }
        }


    suspend fun updateArtist(artist: Artist) =
        firestore
            .collection("artists")
            .document(artist.id)
            .set(artist)


    suspend fun deleteArtist(artist: Artist) =
        firestore
            .runTransaction {
                firestore.collection("artists").document(artist.id).let { delete(it) }
                firestore.collection("handles").document(artist.handle).let { delete(it) }
            }

    suspend fun handleExists(handle: String) =
        firestore
            .collection("handles")
            .document(handle)
            .get()
            .exists

}