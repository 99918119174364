import androidx.compose.runtime.Composable
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.platform.LocalWindowInfo

actual class ScreenInfo {
    @OptIn(ExperimentalComposeUiApi::class)
    @Composable
    actual fun isPortrait(): Boolean {
        val containerSize = LocalWindowInfo.current.containerSize
        val portrait = containerSize.height > containerSize.width
        return portrait
    }
}