package ui.create

import Log
import SpotifyArtist
import Track
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import data.LocalSpotify
import io.ktor.http.Url
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import loca.composeapp.generated.resources.Res
import loca.composeapp.generated.resources.search
import org.jetbrains.compose.resources.vectorResource
import ui.component.LocaTextField

@Composable
fun FindBand(onSelectArtist: (SpotifyArtist, List<Track>) -> Unit) {
    var spotify by remember { mutableStateOf("") }
    var linkError by remember { mutableStateOf(false) }
    var query by remember { mutableStateOf("") }

    var results = remember { mutableStateOf(listOf<SpotifyArtist>()) }

    var spotifyRepository = LocalSpotify.current

    val coroutineScope = rememberCoroutineScope { Dispatchers.Unconfined }

    var autoCompleteJob: Job? = null

    var loading by remember { mutableStateOf(false) }
    var usingSearch by remember { mutableStateOf(true) }

    Box(Modifier.fillMaxSize()) {
        Column (
            modifier = Modifier.align(Alignment.Center)
        ) {

            Spacer(Modifier.height(48.dp))

            Text(
                modifier = Modifier.align(Alignment.CenterHorizontally),
                text = "Let's start with your Spotify",
                fontWeight = FontWeight.Bold,
                fontSize = 20.sp
            )

            Spacer(Modifier.height(24.dp))


            Column {
                if (usingSearch) {
                    LocaTextField(
                        modifier = Modifier
                            .fillMaxWidth()
                            .align(Alignment.CenterHorizontally),
                        value = query,
                        onValueChange = {
                            query = it

                            autoCompleteJob = coroutineScope.launch {
                                if (query != "") {
                                    loading = true
                                    val toSearch = query
                                    autoCompleteJob?.cancel()
                                    results.value = listOf()
                                    delay(1500)
                                    if (query != "" && query == toSearch) {
                                        val artists = spotifyRepository.searchArtists(query)
                                        if (query == toSearch) {
                                            results.value = artists
                                            loading = false
                                        }
                                    }
                                } else {
                                    loading = false
                                }
                            }
                        },
                        label = "Search",
                        leadingIcon = {
                            Icon(
                                imageVector = vectorResource(Res.drawable.search),
                                contentDescription = "Search"
                            )
                        },
                        trailingIcon = {
                            if (loading) {
                                CircularProgressIndicator(
                                    Modifier.size(24.dp)
                                )
                            }
                        }
                    )
                } else {
                    LocaTextField(
                        modifier = Modifier,
                        value = spotify,
                        onValueChange = {
                            linkError = false
                            spotify = it
                        },
                        label = "Spotify Link",
                        isError = linkError,
                        placeholder = "https://open.spotify.com/artist/7dhag"
                    )
                }


                if (spotify.isNotEmpty()) {
                    Button(
                        modifier = Modifier.fillMaxWidth(),
                        onClick = {
                            try {
                                val link = Url(spotify)
                                val pathSegments = link.pathSegments
                                if (pathSegments.size < 2) throw Exception("Not a URL")
                                Log().i("URL", pathSegments.toString())
                                if (
                                    link.host.contains("spotify.com") &&
                                    pathSegments.getOrNull(1) == "artist"
                                ) {
                                    coroutineScope.launch {
                                        try {
                                            val id = pathSegments[2]
                                            onSelectArtist(
                                                spotifyRepository.getArtistDetails(id),
                                                spotifyRepository.getTopTracks(id)
                                            )
                                        } catch (e: Exception) {
                                            linkError = true
                                        }
                                    }
                                } else {
                                    linkError = true
                                }
                            } catch (e: Exception) {
                                coroutineScope.launch {
                                    try {
                                        onSelectArtist(
                                            spotifyRepository.getArtistDetails(spotify),
                                            spotifyRepository.getTopTracks(spotify)
                                        )
                                    } catch (e: Exception) {
                                        linkError = true
                                    }
                                }
                            }
                        },
                        shape = CircleShape
                    ) {
                        Text("Next")
                    }
                } else {
                    OutlinedButton(
                        modifier = Modifier.fillMaxWidth(),
                        onClick = {
                            usingSearch = !usingSearch
                            results.value = listOf()
                        },
                        shape = CircleShape
                    ) {
                        Text(if (usingSearch) "Paste a link" else "Search on Spotify")
                    }
                }

                LazyColumn(
                    modifier = Modifier
                        .fillMaxWidth()
                        .weight(1f, true)
//                    .clip(RoundedCornerShape(24.dp))
//                    .background(MaterialTheme.colorScheme.surfaceContainer)
                ) {
//                item {
//                    Spacer(Modifier.height(12.dp))
//                }

                    items(results.value) {
                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .clip(RoundedCornerShape(24.dp))
                                .clickable {
                                    coroutineScope.launch {
                                        val tracks = spotifyRepository.getTopTracks(it.id)
                                        onSelectArtist(it, tracks)
                                    }
                                }
                                .padding(24.dp)
                        ) {
                            AsyncImage(
                                modifier = Modifier
                                    .size(72.dp)
                                    .clip(CircleShape)
                                    .align(Alignment.CenterVertically),
                                model = it.images.firstOrNull()?.url ?: "",
                                contentDescription = "Artist Picture"
                            )

                            Spacer(Modifier.width(12.dp))

                            Column(
                                modifier = Modifier.align(Alignment.CenterVertically),
                            ) {
                                Text(
                                    text = it.name,
                                    fontWeight = FontWeight.Medium,
                                    fontSize = 16.sp
                                )

                                it.genres?.takeIf { it.isNotEmpty() }?.let { genres ->
                                    Text(
                                        text = genres.take(3).joinToString(" / "),
                                        fontSize = 14.sp
                                    )
                                }

                                it.followers?.let { followers ->
                                    Text(
                                        text = "${followers.total?.formatWithThousandComma()} followers",
                                        fontSize = 12.sp
                                    )
                                }

                                Text(
                                    text = "https://spotify.com/artists/${it.id}",
                                    fontSize = 12.sp,
                                    color = MaterialTheme.colorScheme.onBackground.copy(alpha = 0.5f)
                                )
                            }
                        }
                    }
                }
            }
        }

    }
}

fun Int.formatWithThousandComma(): String {
    val result = StringBuilder()
    val size = this.toString().length
    return if (size > 3) {
        for (i in size - 1 downTo 0) {
            result.insert(0, this.toString()[i])
            if ((i != size - 1) && i != 0 && (size - i) % 3 == 0)
                result.insert(0, ",")
        }
        result.toString()
    } else
        this.toString()
}