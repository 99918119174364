package data

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

actual class SpotifyPlayer {
    actual fun connect() {
    }

    actual fun play(id: String) {
    }

    actual fun playerState(): Flow<SpotifyPlayerState> {
        return flow {  }
    }

    actual fun pause() {
    }

    actual fun resume() {
    }

}