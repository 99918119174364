package ui

import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.tween
import androidx.compose.material3.ColorScheme
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Typography
import androidx.compose.material3.darkColorScheme
import androidx.compose.material3.lightColorScheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import com.materialkolor.PaletteStyle
import com.materialkolor.dynamicColorScheme
import loca.composeapp.generated.resources.Inter_Bold
import loca.composeapp.generated.resources.Inter_Medium
import loca.composeapp.generated.resources.Inter_Regular
import loca.composeapp.generated.resources.Inter_SemiBold
import loca.composeapp.generated.resources.Res
import org.jetbrains.compose.resources.Font

val locaLightColors = lightColorScheme(
    primary = Color(0xFF873EFF),
    background = Color(0xFFFAFAFA),
    surface = Color(0xFFf1f1f1),
)

val locaDarkColors = darkColorScheme(
    primary = Color(0xFFa29bfe),
    onPrimary = Color(0xFF2d3436),
    background = Color(0xFF2d3436),
    surface = Color(0xFF1e272e),
)

fun materialScheme(isDark: Boolean) = if (isDark) darkColorScheme() else lightColorScheme()

fun defaultColors(isDark: Boolean) = dynamicColorScheme(
    seedColor = Color(0xFF6930C3),
    isDark = isDark,
    isAmoled = false,
    style = PaletteStyle.TonalSpot
)

val background1 = Color(0xFFe0e1dd)
val background2 = Color(0xFF90e0ef)
val background3 = Color(0xFF212529)
val background4 = Color(0xFFE935F8)
val background5 = Color(0xFF0a9396)
val backgrounds = listOf(background1, background2, background3, background4, background5)

val accent1 = Color(0xFF2a9d8f)
val accent2 = Color(0xFF219ebc)
val accent3 = Color(0xFFc1121f)
val accent4 = Color(0xFF7371fc)
val accent5 = Color(0xFFf4d35e)
val accents = listOf(accent1, accent2, accent3, accent4, accent5)


@Composable
fun interFontFamily() = FontFamily(
    Font(Res.font.Inter_Regular, weight = FontWeight.Normal),
    Font(Res.font.Inter_Medium, weight = FontWeight.Medium),
    Font(Res.font.Inter_SemiBold, weight = FontWeight.SemiBold),
    Font(Res.font.Inter_Bold, weight = FontWeight.Bold)
)

@Composable
fun InterTypography() = Typography().run {
    val fontFamily = interFontFamily()
    copy(
        displayLarge = displayLarge.copy(fontFamily = fontFamily),
        displayMedium = displayMedium.copy(fontFamily = fontFamily),
        displaySmall = displaySmall.copy(fontFamily = fontFamily),
        headlineLarge = headlineLarge.copy(fontFamily = fontFamily),
        headlineMedium = headlineMedium.copy(fontFamily = fontFamily),
        headlineSmall = headlineSmall.copy(fontFamily = fontFamily),
        titleLarge = titleLarge.copy(fontFamily = fontFamily),
        titleMedium = titleMedium.copy(fontFamily = fontFamily),
        bodyLarge = bodyLarge.copy(fontFamily = fontFamily),
        bodyMedium = bodyMedium.copy(fontFamily = fontFamily),
        labelLarge = labelLarge.copy(fontFamily = fontFamily, fontWeight = FontWeight.SemiBold),
        labelMedium = labelMedium.copy(fontFamily = fontFamily),
        labelSmall = labelSmall.copy(fontFamily = fontFamily)
    )
}

@Composable
fun LocaTheme(
    colors: ColorScheme,
    content: @Composable () -> Unit
) = MaterialTheme(
    colorScheme = colors.switch(),
    typography = InterTypography(),
    content = content
)

@Composable
private fun animateColor(targetValue: Color) =
    animateColorAsState(
        targetValue = targetValue,
        animationSpec = tween(durationMillis = 300)
    ).value

@Composable
fun ColorScheme.switch() = copy(
    primary = animateColor(primary),
    primaryContainer = animateColor(primaryContainer),
    secondary = animateColor(secondary),
    secondaryContainer = animateColor(secondaryContainer),
    background = animateColor(background),
    surface = animateColor(surface),
    error = animateColor(error),
    onPrimary = animateColor(onPrimary),
    onSecondary = animateColor(onSecondary),
    onBackground = animateColor(onBackground),
    onSurface = animateColor(onSurface),
    onError = animateColor(onError)
)